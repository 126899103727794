import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { Row, TinyIcon } from "core/styles";

const HeaderWithTooltipContainer = styled(Row)`
  gap: 5px;
  align-items: center;
  min-width: 40px;
  svg {
    color: #000;
  }
`;

const HeaderWithTooltip = ({
  info,
  children,
  clickable = false,
  iconStyle,
}) => {
  return (
    <HeaderWithTooltipContainer>
      {children}{" "}
      <TinyIcon
        icon={faCircleInfo}
        id={children}
        style={{ ...iconStyle, cursor: "pointer" }}
      />
      <Tooltip
        anchorId={children}
        html={typeof info === "string" ? info : null}
        content={typeof info !== "string" ? info : null}
        positionStrategy="fixed"
        style={{ zIndex: "1000" }}
        clickable={clickable}
      />
    </HeaderWithTooltipContainer>
  );
};

export default HeaderWithTooltip;

import styled from "styled-components";
import PropTypes from "prop-types";

const variants = {
  h1: styled.h1`
    color: ${(props) => props.theme.color[props.color || "heading"]};
    font-family: "Inter";
    font-size: 35px;
    font-weight: 700;
    line-height: 38px;
    margin: ${({
      theme,
      noMargin,
      ml = "0",
      mr = "0",
      mb = "h1",
      mt = "0",
    }) => {
      if (noMargin) {
        return "0px";
      } else {
        let top = theme.margin[mt];
        let right = theme.margin[mr];
        let bottom = theme.margin[mb];
        let left = theme.margin[ml];

        return `${top} ${right} ${bottom} ${left}`;
      }
    }};
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  h2: styled.h2`
    color: ${(props) => props.theme.color[props.color || "heading"]};
    font-family: "Inter";
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 36px;
    margin: ${({
      theme,
      noMargin,
      ml = "0",
      mr = "0",
      mb = "h2",
      mt = "0",
    }) => {
      if (noMargin) {
        return "0px";
      } else {
        let top = theme.margin[mt];
        let right = theme.margin[mr];
        let bottom = theme.margin[mb];
        let left = theme.margin[ml];

        return `${top} ${right} ${bottom} ${left}`;
      }
    }};
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  h3: styled.h3`
    color: ${(props) => props.theme.color[props.color || "heading"]};
    font-family: "Inter";
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 26px;
    margin: ${({
      theme,
      noMargin,
      ml = "0",
      mr = "0",
      mb = "h3",
      mt = "0",
    }) => {
      if (noMargin) {
        return "0px";
      } else {
        let top = theme.margin[mt];
        let right = theme.margin[mr];
        let bottom = theme.margin[mb];
        let left = theme.margin[ml];

        return `${top} ${right} ${bottom} ${left}`;
      }
    }};
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  h4: styled.h4`
    color: ${(props) => props.theme.color[props.color || "body"]};
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    margin: ${({
      theme,
      noMargin,
      ml = "0",
      mr = "0",
      mb = "h4",
      mt = "0",
    }) => {
      if (noMargin) {
        return "0px";
      } else {
        let top = theme.margin[mt];
        let right = theme.margin[mr];
        let bottom = theme.margin[mb];
        let left = theme.margin[ml];

        return `${top} ${right} ${bottom} ${left}`;
      }
    }};
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  h5: styled.h5`
    color: ${(props) => props.theme.color[props.color || "body"]};
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    margin: ${({
      theme,
      noMargin,
      ml = "0",
      mr = "0",
      mb = "h5",
      mt = "0",
    }) => {
      if (noMargin) {
        return "0px";
      } else {
        let top = theme.margin[mt];
        let right = theme.margin[mr];
        let bottom = theme.margin[mb];
        let left = theme.margin[ml];

        return `${top} ${right} ${bottom} ${left}`;
      }
    }};
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  h6: styled.h6`
    color: ${(props) => props.theme.color[props.color || "body"]};
    font-family: "Inter";
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin: ${({
      theme,
      noMargin,
      ml = "0",
      mr = "0",
      mb = "h6",
      mt = "0",
    }) => {
      if (noMargin) {
        return "0px";
      } else {
        let top = theme.margin[mt];
        let right = theme.margin[mr];
        let bottom = theme.margin[mb];
        let left = theme.margin[ml];

        return `${top} ${right} ${bottom} ${left}`;
      }
    }};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  p: styled.p`
    display: block;
    color: ${(props) => props.theme.color[props.color || "body"]};
    font-family: "Inter";
    font-size: 16px;
    line-height: 18px;
    margin: ${({ theme, noMargin, ml = "0", mr = "0", mb = "p", mt = "0" }) => {
      if (noMargin) {
        return "0px";
      } else {
        let top = theme.margin[mt];
        let right = theme.margin[mr];
        let bottom = theme.margin[mb];
        let left = theme.margin[ml];

        return `${top} ${right} ${bottom} ${left}`;
      }
    }};
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  "sub-text": styled.div`
    color: ${(props) => props.theme.color[props.color || "body"]};
    font-family: "Inter";
    font-size: 12px;
    line-height: 14px;
    margin: ${({
      theme,
      noMargin,
      ml = "0",
      mr = "0",
      mb = "sub",
      mt = "0",
    }) => {
      if (noMargin) {
        return "0px";
      } else {
        let top = theme.margin[mt];
        let right = theme.margin[mr];
        let bottom = theme.margin[mb];
        let left = theme.margin[ml];

        return `${top} ${right} ${bottom} ${left}`;
      }
    }};
    overflow: hidden;
    text-overflow: ellipsis;
  `,
};

const Typography = ({ variant = "p", children, title, ...rest }) => {
  const Component = variants[variant];

  return (
    <Component title={title || children} {...rest}>
      {children}
    </Component>
  );
};

Typography.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "sub-text",
  ]),
};

export default Typography;

import { useEffect, useMemo, useState } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  // useNavigate,
  useParams,
} from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  faArrowLeft,
  faChevronRight,
  faCircleNodes,
  faFileCertificate,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import { faBuildingUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import styled, { ThemeProvider } from "styled-components";
import { DateTime } from "luxon";
import {
  faListUl,
  faKey,
  faIdCardClip,
  faSliders,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck,
  faEllipsis,
  faExclamation,
  faBan,
  faBracketsCurly,
} from "@fortawesome/pro-solid-svg-icons";
import InfoFlash from "core/components/InfoFlash";

import theme from "theme";
import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useCurrentUser from "core/hooks/useCurrentUser";
import OrgDetailsForm from "modules/admin/OrgDetailsForm";
import OrgSegmentSwitcher from "modules/admin/OrgSegmentSwitcher";
import OrgUserLicenseForm from "modules/admin/OrgUserLicenseForm";
import OrgProductLicenseForm from "modules/admin/OrgProductLicenseForm";
import {
  getOrganizationDeployment,
  getOrganizationOnboarding,
  updateOrganization,
  setupOrganization,
  deployLicense,
  updateOrganizationLicenses,
  getUsers,
  getCustomerSegments,
  getProductCatalogVersion,
  getOrganizationById,
} from "modules/admin/actions";
import useToaster from "core/hooks/useToaster";
import { DEFAULT_ORG, FLAG_KEYS } from "core/constants";
import Typography from "core/components/Typography";
import { Column, Row } from "core/styles";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import Pill from "core/components/Pill";
import { InputGroup, Label } from "core/components/Form";
import Toggle from "core/components/Toggle";
import OrgIntegrationsForm from "modules/admin/OrgIntegrationsForm";
import OrgPreferencesForm from "modules/admin/OrgPreferencesForm";
import Flex from "core/components/Flex";
import { SideMenuContainer, SubNav } from "modules/admin/SubNav";
import { CredentialNavSection } from "modules/admin/CredentialNavSection";
import { ROLES } from "core/constants";
import DetailsViewer from "modules/admin/DetailsViewer";
import { useCheckFeatureFlag } from "core/hooks/featureFlagging";
import Utils from "common/utilities";
import ReactJsonView from "@microlink/react-json-view";
import Modal from "core/components/Modal";
import UserManagement from "./user-management";
import ManagedProviders from "pages/config/managed-providers";
import { SecondaryButton } from "core/components/QueryBuilder/styles";

const ONBOARDING_LABELS = {
  "not onboarded": "Not Setup",
  timeout: "Timeout",
  "in progress": "In Progress",
  succeeded: "Active",
  failed: "Error",
  inactive: "Inactive",
  queued: "Queued",
  unknown: "Unknown",
};

const ONBOARDING_ICONS = {
  "not onboarded": faExclamation,
  timeout: faClose,
  "in progress": faEllipsis,
  succeeded: faCheck,
  failed: faClose,
  queued: faEllipsis,
  unknown: faExclamation,
  inactive: faBan,
};

const ONBOARDING_VARIANTS = {
  "not onboarded": "default",
  timeout: "failed",
  "in progress": "info",
  succeeded: "success",
  failed: "failed",
  queued: "pending",
  unknown: "warning",
  inactive: "default",
};

const DEPLOYMENT_STATUSES = {
  DEFAULT: "Not Configured",
  SUCCESS: "Deployed",
  IN_PROGRESS: "In Progress",
  FAILED: "Failed",
  TIMEOUT: "Timeout",
  QUEUED: "Queued",
  UNKNOWN: "Unknown",
};

const DEPLOYMENT_ICONS = {
  [DEPLOYMENT_STATUSES.DEFAULT]: faExclamation,
  [DEPLOYMENT_STATUSES.SUCCESS]: faCheck,
  [DEPLOYMENT_STATUSES.IN_PROGRESS]: faEllipsis,
  [DEPLOYMENT_STATUSES.FAILED]: faClose,
  [DEPLOYMENT_STATUSES.QUEUED]: faEllipsis,
  [DEPLOYMENT_STATUSES.TIMEOUT]: faClose,
  [DEPLOYMENT_STATUSES.UNKNOWN]: faExclamation,
};

const DEPLOYMENT_STATUS_MAP = {
  "not deployed": DEPLOYMENT_STATUSES.DEFAULT,
  "in progress": DEPLOYMENT_STATUSES.IN_PROGRESS,
  queued: DEPLOYMENT_STATUSES.QUEUED,
  succeeded: DEPLOYMENT_STATUSES.SUCCESS,
  failed: DEPLOYMENT_STATUSES.FAILED,
  timeout: DEPLOYMENT_STATUSES.TIMEOUT,
  unknown: DEPLOYMENT_STATUSES.UNKNOWN,
};

const DEPLOYMENT_STATUS_PILL_VARIANTS = {
  "not deployed": "default",
  "in progress": "info",
  queued: "pending",
  succeeded: "success",
  failed: "failed",
  timeout: "failed",
  unknown: "warning",
};

const REFETCH_SHORT = 10 * 1000;
const REFETCH_LONG = 60 * 1000;
const dateTimeFormat = "FF";
const durationFormat = "m'm ' s's'";

const ViewJsonButton = styled.div`
  position: relative;
  margin-left: 10px;
  z-index: 100;
  cursor: pointer;

  & > svg {
    margin-left: 0px !important;

    :hover {
      background-color: #6842c0;
    }
  }
`;

const JSONContainer = styled.div`
  text-align: left;
  background-color: #fff;
  font-family: Inter;
  letter-spacing: 0.6px;
  font-size: 16px;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 20px;
  max-height: 80vh;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  font-weight: normal;

  .jstree-anchor {
    white-space: normal;
    height: auto;
  }
  .jstree-default .jstree-anchor {
    height: auto;
  }

  & * {
    white-space: normal !important;
  }

  & > ul {
    background-color: transparent !important;
  }
`;

const StatusTooltip = ({ data, onCodeClick }) => {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          textAlign: "left",
          width: "100%",
        }}
      >
        <div style={{ position: "relative", whiteSpace: "break-spaces" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4" color="white">
              Summary
            </Typography>
            {onCodeClick && (
              <ViewJsonButton onClick={onCodeClick}>
                <FontAwesomeIcon
                  icon={faBracketsCurly}
                  style={{
                    color: "#fff",
                    border: "1px solid #fff",
                    borderRadius: "100%",
                    padding: "4px",
                  }}
                />
              </ViewJsonButton>
            )}
          </div>
          <Typography variant="p" color="white">
            <strong>Status:</strong> {data.status}
          </Typography>
          {data.message && (
            <Typography variant="p" color="white">
              <strong>Message:</strong> {data.message}
            </Typography>
          )}
          <Typography variant="p" color="white">
            <strong>Start Time:</strong>{" "}
            {data.licenseDeploymentStart?.toFormat(dateTimeFormat)}
          </Typography>
          {data.licenseDeploymentFinish && (
            <Typography variant="p" color="white">
              <strong>Duration:</strong>{" "}
              {data.licenseDeploymentFinish
                .diff(data.licenseDeploymentStart)
                .toFormat(durationFormat)}
            </Typography>
          )}
        </div>
        {["succeeded"].includes(data.rawStatus) && (
          <>
            <div>
              <Typography variant="h4" color="white">
                BI
              </Typography>
              <Typography variant="p" color="white">
                <strong>Success:</strong> {data.isBiDeployed}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Start Time:</strong>{" "}
                {data.biDeploymentStart.toFormat(dateTimeFormat)}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Duration:</strong>{" "}
                {data.biDeploymentFinish
                  .diff(data.biDeploymentStart)
                  .toFormat(durationFormat)}
              </Typography>
            </div>
            <div>
              <Typography variant="h4" color="white">
                Data
              </Typography>
              <Typography variant="p" color="white">
                <strong>Success:</strong> {data.isDataDeployed}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Start Time:</strong>{" "}
                {data.dataDeploymentStart.toFormat(dateTimeFormat)}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Duration:</strong>{" "}
                {data.dataDeploymentFinish
                  .diff(data.dataDeploymentStart)
                  .toFormat(durationFormat)}
              </Typography>
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

const buildStatusObject = (data, STATUS_MAP) => {
  const BI_MAPPER = {
    is_success: (boolean) => ({ isBiDeployed: String(boolean) }),
    start_timestamp: (timestamp) => ({
      biDeploymentStart: DateTime.fromMillis(timestamp),
    }),
    finish_timestamp: (timestamp) => ({
      biDeploymentFinish: DateTime.fromMillis(timestamp),
    }),
  };

  const DATA_MAPPER = {
    is_success: (boolean) => ({ isDataDeployed: String(boolean) }),
    start_timestamp: (timestamp) => ({
      dataDeploymentStart: DateTime.fromMillis(timestamp),
    }),
    finish_timestamp: (timestamp) => ({
      dataDeploymentFinish: DateTime.fromMillis(timestamp),
    }),
  };

  const SUMMARY_MAPPER = {
    status: (rawStatus) => ({
      rawStatus: rawStatus || "unknown",
      status: STATUS_MAP[rawStatus || "unknown"],
    }),
    message: (string) => ({ message: string }),
    deployment_number: (number) => ({ deploymentNumber: number }),
    start_timestamp: (timestamp) => ({
      licenseDeploymentStart: timestamp ? DateTime.fromMillis(timestamp) : null,
    }),
    finish_timestamp: (timestamp) => ({
      licenseDeploymentFinish: timestamp
        ? DateTime.fromMillis(timestamp)
        : null,
    }),
    last_successful_timestamp: (timestamp) => ({
      licenseLastSuccessfulDeployment: timestamp
        ? DateTime.fromMillis(timestamp)
        : null,
    }),
  };

  const defaultObject = {
    rawStatus: "unknown",
    status: STATUS_MAP["unknown"],
  };

  const biObject = data.bi
    ? Object.keys(data.bi).reduce((object, key) => {
        if (BI_MAPPER[key]) {
          return {
            ...object,
            ...BI_MAPPER[key](data.bi[key]),
          };
        }

        return object;
      }, {})
    : {};

  const dataObject = data.rows
    ? Object.keys(data.rows).reduce((object, key) => {
        if (DATA_MAPPER[key]) {
          return {
            ...object,
            ...DATA_MAPPER[key](data.rows[key]),
          };
        }

        return object;
      }, {})
    : {};

  const licenseObject = data
    ? Object.keys(data).reduce((object, key) => {
        if (SUMMARY_MAPPER[key]) {
          return {
            ...object,
            ...SUMMARY_MAPPER[key](data[key]),
          };
        }

        return object;
      }, {})
    : {};

  return {
    ...defaultObject,
    ...biObject,
    ...dataObject,
    ...licenseObject,
    jsonData: licenseObject,
  };
};

const DetailsNavigationMenu = ({
  onboarding,
  isOnboardingFetching,
  deployment,
  isDeploymentFetching,
  orgIsActive,
  locationFrom = "",
}) => {
  const {
    currentUser: { role },
  } = useCurrentUser();
  const hasDataAccessFeature = useCheckFeatureFlag(
    FLAG_KEYS.DATA_ACCESS_MANAGEMENT
  );

  return (
    <SideMenuContainer>
      <AnimatePresence mode="wait">
        <SubNav
          to="general"
          key="general"
          icon={faListUl}
          label="General"
          statusIcon={
            orgIsActive
              ? ONBOARDING_ICONS[onboarding?.rawStatus] || faExclamation
              : ONBOARDING_ICONS["inactive"]
          }
          statusVariant={
            orgIsActive
              ? ONBOARDING_VARIANTS[onboarding?.rawStatus] || "default"
              : "default"
          }
          isStatusLoading={isOnboardingFetching}
          locationState={{ from: locationFrom }}
        />

        {[ROLES.systemAdmin, ROLES.systemViewer].includes(role) && (
          <SubNav
            key="bi"
            to="bi"
            icon={faIdCardClip}
            label="User Licenses"
            locationState={{ from: locationFrom }}
          />
        )}

        {[ROLES.systemAdmin, ROLES.systemViewer, ROLES.orgAdmin].includes(
          role
        ) && (
          <SubNav
            key="users"
            to="users"
            icon={faUsers}
            label="Users"
            locationState={{ from: locationFrom }}
          />
        )}

        {role === ROLES.systemAdmin && (
          <SubNav
            key="product"
            to="product"
            icon={faKey}
            label="Product Licenses"
            statusIcon={
              DEPLOYMENT_ICONS[DEPLOYMENT_STATUS_MAP[deployment?.rawStatus]]
            }
            statusVariant={
              DEPLOYMENT_STATUS_PILL_VARIANTS[deployment?.rawStatus]
            }
            isStatusLoading={isDeploymentFetching}
            isDisabled={
              orgIsActive
                ? ONBOARDING_VARIANTS[onboarding?.rawStatus] !== "success"
                : true
            }
            locationState={{ from: locationFrom }}
          />
        )}

        {/* {role === ROLES.systemAdmin && (
        <SubNav
          to="configurations"
          icon={faGear}
          label="Data Context"
          locationState={{ from: locationFrom }}
        />
      )} */}

        {[ROLES.orgAdmin, ROLES.systemViewer, ROLES.systemAdmin].includes(
          role
        ) &&
          hasDataAccessFeature && (
            <SubNav
              key="credentials"
              to="credentials"
              icon={faFileCertificate}
              label="Data Credentials"
              isDisabled={
                orgIsActive
                  ? ONBOARDING_VARIANTS[onboarding?.rawStatus] !== "success"
                  : true
              }
              locationState={{ from: locationFrom }}
            />
          )}

        {role === ROLES.systemAdmin && (
          <SubNav
            key="integrations"
            to="integrations"
            icon={faCircleNodes}
            label="Integrations"
            isDisabled={
              orgIsActive
                ? ONBOARDING_VARIANTS[onboarding?.rawStatus] !== "success"
                : true
            }
            locationState={{ from: locationFrom }}
          />
        )}

        {[ROLES.systemAdmin, ROLES.systemViewer].includes(role) && (
          <SubNav
            key="preferences"
            to="preferences"
            icon={faSliders}
            label="Preferences"
            isDisabled={
              orgIsActive
                ? ONBOARDING_VARIANTS[onboarding?.rawStatus] !== "success"
                : true
            }
            locationState={{ from: locationFrom }}
          />
        )}
      </AnimatePresence>
    </SideMenuContainer>
  );
};

const HiddenElement = styled.div`
  margin-top: 15px;
  margin-left: 10px;
  margin-bottom: -15px;
`;

const VisibleOnHoverContainer = styled.span`
  ${HiddenElement} {
    opacity: 0;
  }

  &:hover {
    ${HiddenElement} {
      display: block;
      opacity: 1;
    }
  }
`;

const ContainerWithHiddenElement = ({
  isElementVisible,
  isElementVisibleOnHover,
  hiddenElement,
  children,
}) => {
  if (isElementVisible) {
    return (
      <div>
        {children}
        <HiddenElement>{hiddenElement}</HiddenElement>
      </div>
    );
  }
  if (isElementVisibleOnHover) {
    return (
      <VisibleOnHoverContainer>
        {children}
        <HiddenElement>{hiddenElement}</HiddenElement>
      </VisibleOnHoverContainer>
    );
  }

  return children;
};

const UpdateOrg = () => {
  const { toaster } = useToaster();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const {
    currentUser: { role },
  } = useCurrentUser();

  const [onboardingRefetchInterval, setOnboardingRefetchInterval] =
    useState(REFETCH_LONG);
  const [deploymentRefetchInterval, setDeploymentRefetchInterval] =
    useState(REFETCH_LONG);

  const { orgId: organizationId } = params;

  const [isModalOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [jsonRootName, setJsonRootName] = useState(false);

  const openModalWithData = (data, rootName) => {
    setJsonRootName(rootName);
    setModalContent(data);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setJsonRootName(false);
    setModalContent({});
    setModalIsOpen(false);
  };

  useEffect(() => {
    const pathname = location.pathname; // this gives me current Url's pathname
    localStorage.setItem("pageUrl", pathname);
  }, [location.pathname]);

  const {
    data: allUsers,
    isError: isUsersError,
    error: usersError,
  } = useQuery({
    queryKey: ["users", organizationId],
    queryFn: useAuthenticatedCall(getUsers),
    placeholderData: [],
  });

  useEffect(() => {
    if (isUsersError) {
      if (Array.isArray(usersError)) {
        toaster.error({ message: usersError[0] });
      } else {
        toaster.error({ message: usersError.message });
      }
    }
  }, [isUsersError, usersError, toaster]);

  const fetchQueryCall = useAuthenticatedCall((req) =>
    getOrganizationById({ ...req, organizationId })
  );

  const {
    isLoading: isFetching,
    isError: isOrgError,
    error: orgError,
    data: organization,
    isPlaceholderData: isPlaceholderOrg,
  } = useQuery({
    queryKey: ["organizations", organizationId],
    queryFn: fetchQueryCall,
    placeholderData: DEFAULT_ORG,
    select: (org) => {
      // Merge org with default
      return {
        ...DEFAULT_ORG,
        ...org,
        primaryContact: {
          ...DEFAULT_ORG.primaryContact,
          ...org?.primaryContact,
        },
        domain: org?.domain || [],
        licenses: {
          ...DEFAULT_ORG.licenses,
          ...org?.licenses,
          tableau_user_licenses: {
            ...DEFAULT_ORG.licenses.tableau_user_licenses,
            ...org?.licenses?.tableau_user_licenses,
          },
        },
        preferences: {
          bi_portal: {
            default_states:
              org?.preferences?.bi_portal?.default_states[0] ||
              DEFAULT_ORG.preferences.bi_portal.default_states,
          },
        },
        users: allUsers,
      };
    },
  });

  useEffect(() => {
    if (isOrgError) {
      if (Array.isArray(orgError)) {
        toaster.error({ message: orgError[0] });
      } else {
        toaster.error({ message: orgError.message });
      }
    }
  }, [isOrgError, orgError, toaster]);

  // fetch onboarding data
  const fetchOnboardingCall = useAuthenticatedCall((req) =>
    getOrganizationOnboarding({ ...req, organizationId })
  );
  const {
    isLoading: isOnboardingFetching,
    // isRefetching: isOnboardingRefetching,
    data: onboarding,
  } = useQuery({
    queryKey: ["organization-onboarding", organizationId],
    queryFn: fetchOnboardingCall,
    staleTime: onboardingRefetchInterval,
    refetchInterval: onboardingRefetchInterval,
    select: (data) => {
      const formattedData = buildStatusObject(data, ONBOARDING_LABELS);

      return {
        ...formattedData,
        tooltip: (
          <StatusTooltip
            data={formattedData}
            onCodeClick={
              role === ROLES.systemAdmin
                ? () =>
                    openModalWithData(
                      {
                        onboarding: organization.onboarding,
                      },
                      organizationId
                    )
                : null
            }
          />
        ),
      };
    },
  });

  // update onboarding refetchInterval based on status
  useEffect(() => {
    if (
      onboardingRefetchInterval === REFETCH_SHORT &&
      [
        "not onboarded",
        "timeout",
        "inactive",
        "unknown",
        "failed",
        "succeeded",
      ].includes(onboarding?.rawStatus)
    ) {
      setOnboardingRefetchInterval(REFETCH_LONG);
    }

    if (
      onboardingRefetchInterval === REFETCH_LONG &&
      ![
        "not onboarded",
        "timeout",
        "inactive",
        "unknown",
        "failed",
        "succeeded",
      ].includes(onboarding?.rawStatus)
    ) {
      setOnboardingRefetchInterval(REFETCH_SHORT);
    }
  }, [onboarding?.rawStatus, onboardingRefetchInterval]);

  // fetch deployment data
  const fetchDeploymentCall = useAuthenticatedCall((req) =>
    getOrganizationDeployment({ ...req, organizationId })
  );
  const {
    isLoading: isDeploymentFetching,
    data: deployment,
    refetch: refetchDeploymentStatus,
    isRefetching: isRefetchingDeploymentStatus,
  } = useQuery({
    queryKey: ["organization-deployment", organizationId],
    queryFn: fetchDeploymentCall,
    staleTime: deploymentRefetchInterval,
    refetchInterval: deploymentRefetchInterval,
    enabled: onboarding && onboarding.rawStatus === "succeeded",
    select: (data) => {
      const formattedData = buildStatusObject(data, DEPLOYMENT_STATUS_MAP);

      return {
        ...formattedData,
        tooltip: (
          <StatusTooltip
            data={formattedData}
            onCodeClick={() =>
              openModalWithData(
                {
                  licenses: {
                    license_validation:
                      organization.licenses.license_validation,
                    deployment: organization.licenses.deployment,
                  },
                },

                organizationId
              )
            }
          />
        ),
      };
    },
  });

  // update deployment refetchInterval based on status
  useEffect(() => {
    if (
      deploymentRefetchInterval === REFETCH_SHORT &&
      [
        "not deployed",
        "timeout",
        "inactive",
        "unknown",
        "failed",
        "succeeded",
      ].includes(deployment?.rawStatus)
    ) {
      setDeploymentRefetchInterval(REFETCH_LONG);
    }

    if (
      deploymentRefetchInterval === REFETCH_LONG &&
      ![
        "not deployed",
        "timeout",
        "inactive",
        "unknown",
        "failed",
        "succeeded",
      ].includes(deployment?.rawStatus)
    ) {
      setDeploymentRefetchInterval(REFETCH_SHORT);
    }
  }, [deployment?.rawStatus, deploymentRefetchInterval]);

  const orgDetails = useMemo(() => {
    const {
      id,
      name,
      domain,
      primaryContact,
      active,
      customer_segment,
      medicare_licensed,
    } = organization;

    return {
      id,
      name,
      domain,
      primaryContact,
      active,
      customer_segment,
      medicare_licensed,
    };
  }, [organization]);
  const integrations = useMemo(() => {
    const { callbackUrls } = organization;

    return { callbackUrls };
  }, [organization]);

  const userLicense = useMemo(() => {
    const { user_limit, tableau_user_licenses } = organization.licenses;

    return {
      licenses: {
        user_limit,
        tableau_user_licenses,
      },
    };
  }, [organization]);

  const preferences = useMemo(
    () => ({ preferences: organization.preferences }),
    [organization]
  );

  const updateOrg = useAuthenticatedMutation((req) =>
    updateOrganization({
      ...req,
      organizationId,
    })
  );

  const retryOrgSetup = useAuthenticatedMutation((req) =>
    setupOrganization({ ...req, organizationId })
  );

  const retryLicenseDeployment = useAuthenticatedMutation((req) =>
    deployLicense({ ...req, organizationId })
  );

  const updateLicenses = useAuthenticatedMutation((req) => {
    console.log({ req });
    return updateOrganizationLicenses({ ...req, organizationId });
  });

  const orgMutation = useMutation({
    mutationFn: updateOrg,
    onSuccess: (data, updatedOrg) => {
      /**
       * Since API calls are very slow, we update the cache with the updates for both the
       * single org's cache, and the org list.
       *
       * Then when we invalidate both queries since we don't receive full documents from response.
       *
       */

      // Update local list and detail query cache
      queryClient.setQueryData(
        ["organizations", organizationId],
        (cachedOrg) => {
          if (!cachedOrg) {
            return {
              ...updatedOrg,
              isUpdated: true,
            };
          }

          return { ...cachedOrg, ...updatedOrg, isUpdated: true };
        }
      );
      queryClient.setQueryData(["organizations"], (cachedOrgList) => {
        if (!cachedOrgList || cachedOrgList.length === 0) {
          return [
            {
              ...updatedOrg,
              isUpdated: true,
            },
          ];
        }

        return cachedOrgList.map((cachedOrg) => {
          if (cachedOrg.id === organizationId) {
            return { ...cachedOrg, ...updatedOrg, isUpdated: true };
          }

          return cachedOrg;
        });
      });

      // Invalidate list and detail queries
      queryClient.invalidateQueries({ queryKey: ["organizations"] });

      // Notify
      toaster.success({ message: "Organization has been updated." });
    },
  });

  const setupRetryMutation = useMutation({
    mutationFn: retryOrgSetup,
    onSuccess: async (data, org) => {
      // Notify
      toaster.success({
        message: "Organization setup retry has been started.",
      });
    },
    onError: async (error, variables, context) => {
      console.error("setupRetryMutation", { error, variables, context });
      // Notify
      toaster.error({ message: "Organization setup retry failed." });
    },
    onSettled: () => {
      // Optimistically update status to in progress
      queryClient.setQueryData(
        ["organization-onboarding", organizationId],
        (previous) => {
          return { ...previous, status: "in progress" };
        }
      );
    },
  });

  const licenseDeployRetryMutation = useMutation({
    mutationFn: retryLicenseDeployment,
    onSuccess: async (data, org) => {
      refetchDeploymentStatus();

      // Notify
      toaster.success({
        message: "License deployment retry has been started.",
      });
    },
    onError: async (error, variables, context) => {
      console.error("licenseDeployRetryMutation", {
        error,
        variables,
        context,
      });
      // Notify
      toaster.error({ message: "License deployment retry failed." });
    },
  });

  const licenseMutation = useMutation({
    mutationFn: updateLicenses,
    onSuccess: (data, licenseUpdates) => {
      // Invalidate cached query results
      queryClient.invalidateQueries({ queryKey: ["organizations"] });

      // Notify
      toaster.success({
        message: "Licenses have been updated.",
      });
    },
  });

  const handleDetailsFormSubmit = orgMutation.mutate;
  const handleUserLicenseFormSubmit = licenseMutation.mutate;
  const handlePreferencesFormSubmit = (formData) => {
    const selectedDefaultState = formData.preferences.bi_portal.default_states;

    return orgMutation.mutate({
      preferences: {
        bi_portal: {
          default_states: [selectedDefaultState],
        },
      },
    });
  };

  const [showCodeView, setShowCodeView] = useState(false);
  const [isHoldingShift, setHoldingShift] = useState(false);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.key === "Shift") {
        setHoldingShift(true);
      }
    };

    const onKeyUp = (event) => {
      if (event.key === "Shift") {
        setHoldingShift(false);
      }
    };

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
    // eslint-disable-next-line
  }, []);

  const orgStatus = organization?.active
    ? ONBOARDING_LABELS[onboarding?.rawStatus || "unknown"]
    : ONBOARDING_LABELS["inactive"];

  const orgVariant = organization?.active
    ? ONBOARDING_VARIANTS[onboarding?.rawStatus || "unknown"]
    : ONBOARDING_VARIANTS["inactive"];

  const fetchCustomerSegments = useAuthenticatedCall(getCustomerSegments);
  const { data: selectedCustomerSegment } = useQuery({
    queryKey: ["customer-segments", null, {}],
    queryFn: fetchCustomerSegments,
    enabled: !!orgDetails?.customer_segment,
    select: (data) => {
      const customerSegment = Object.values(data).find(
        (item) => item.id === orgDetails?.customer_segment
      );
      const customerSegmentName = customerSegment?.name;

      const isMedicareLicensable = Object.values(data).find(
        (item) => item.id === orgDetails?.customer_segment
      )?.is_medicare_licensable;

      const env = Utils.getEnv();
      const isDevEnv = env === "development";
      const isPrOrg = isDevEnv && customerSegment?.pr_org === orgDetails?.id;

      return {
        customerSegmentName,
        isMedicareLicensable,
        isPrOrg,
      };
    },
  });

  const fetchCatalogVersion = useAuthenticatedCall((req) => {
    return getProductCatalogVersion({ ...req, isPrCatalog: true });
  });
  const { data: prNumberForPrCatalog } = useQuery({
    queryKey: ["pr-catalog-version"],
    queryFn: fetchCatalogVersion,
    enabled: !!selectedCustomerSegment?.isPrOrg,
    select: (data) => {
      return data?.pr_number;
    },
  });

  const { from } = location.state || {};
  const match = useMatch("/admin/org-management/:orgId/update/product*");

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "24px",
          left: "38px",
        }}
      >
        <SecondaryButton
          onClick={() => {
            navigate("/admin/org-management");
          }}
          style={{ display: "flex", width: "fit-content" }}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={{ marginRight: "5px", marginBottom: "-1px" }}
          />
          <Typography variant="sub-text" noMargin>
            Back to Organizations
          </Typography>
        </SecondaryButton>
      </div>
      <Flex direction="column">
        <Flex direction="row" minWidth="1000px">
          <Flex
            direction="column"
            width="305px"
            height="100vh"
            style={{
              backgroundColor: "#EFE9F8",
              padding: "80px 40px",
              paddingTop: "60px",
            }}
          >
            <Column gap="medium" alignItems="start">
              <Row gap="12px" alignItems="start">
                <FontAwesomeIcon
                  icon={faBuildingUser}
                  style={{
                    width: "28px",
                    height: "28px",
                    padding: "7px 8px",
                    paddingBottom: "7px",
                    color: "#fff",
                    backgroundColor: "#D68712",
                    borderRadius: "5px",
                  }}
                />
                <div>
                  <Typography
                    variant="h4"
                    noMargin
                    style={{ marginBottom: "4px" }}
                  >
                    {isFetching || isPlaceholderOrg || isOrgError ? (
                      <Skeleton style={{ width: "100px" }} />
                    ) : (
                      `${organization.id || "—"}`
                    )}
                  </Typography>
                  <Typography
                    variant="sub-text"
                    noMargin
                    style={{ maxWidth: "230px" }}
                  >
                    {isFetching || isPlaceholderOrg || isOrgError ? (
                      <Skeleton style={{ width: "150px" }} />
                    ) : (
                      organization.name || "n/a"
                    )}
                  </Typography>
                </div>
              </Row>

              <ContainerWithHiddenElement
                hiddenElement={
                  <InputGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content",
                      gap: "8px",
                    }}
                  >
                    <Label style={{ textAlign: "center" }}>Code View</Label>
                    <Toggle
                      id="showCodeView"
                      value={showCodeView}
                      onChange={setShowCodeView}
                    />
                  </InputGroup>
                }
                isElementVisibleOnHover={
                  location.pathname.includes("product") && isHoldingShift
                }
                isElementVisible={
                  location.pathname.includes("product") && showCodeView
                }
              >
                <DetailsNavigationMenu
                  onboarding={onboarding}
                  isOnboardingFetching={isOnboardingFetching}
                  deployment={deployment}
                  isDeploymentFetching={isDeploymentFetching}
                  orgIsActive={organization?.active}
                  locationFrom={from}
                />
              </ContainerWithHiddenElement>

              <div id="portal__devtools" />
            </Column>
          </Flex>

          <Flex
            direction="column"
            grow="1"
            style={{
              height: "100vh",
              overflow: "auto",
              position: "relative",
              padding: "80px 64px",
              paddingTop: "60px",
            }}
          >
            <AnimatePresence mode="wait">
              <Routes key={match ? match : location}>
                <Route
                  index
                  element={<Navigate replace to="general" state={{ from }} />}
                />

                <Route
                  path="general"
                  element={
                    <motion.div
                      key="general"
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      style={{ maxWidth: "500px" }}
                    >
                      <Flex
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        marginBottom="5px"
                      >
                        <Typography variant="h2" noMargin>
                          General
                        </Typography>
                        <div>
                          {onboarding ? (
                            <Pill
                              text={orgStatus}
                              size="large"
                              variant={orgVariant}
                              tooltipId={`details-${organizationId}`}
                              textDataCy="onboardingStatus"
                              tooltipContent={
                                organization?.active
                                  ? ["succeeded", "timeout", "failed"].includes(
                                      onboarding.rawStatus
                                    )
                                    ? onboarding.tooltip
                                    : null
                                  : null
                              }
                            />
                          ) : (
                            <Skeleton
                              style={{ width: "50px", height: "30px" }}
                            />
                          )}
                        </div>
                      </Flex>

                      {["queued", "in progress"].includes(
                        onboarding?.rawStatus
                      ) ? (
                        <InfoFlash
                          style={{ marginTop: "12px", marginBottom: "16px" }}
                        >
                          This page is restricted to read-only while onboarding
                          is in progress.
                        </InfoFlash>
                      ) : [
                          DEPLOYMENT_STATUSES.QUEUED,
                          DEPLOYMENT_STATUSES.IN_PROGRESS,
                        ].includes(deployment?.status) ? (
                        <InfoFlash
                          style={{ marginTop: "12px", marginBottom: "16px" }}
                        >
                          This page is restricted to read-only while a
                          deployment is in progress.
                        </InfoFlash>
                      ) : null}

                      {role === ROLES.systemAdmin &&
                      ![
                        DEPLOYMENT_STATUSES.QUEUED,
                        DEPLOYMENT_STATUSES.IN_PROGRESS,
                      ].includes(deployment?.status) &&
                      !["queued", "in progress"].includes(
                        onboarding?.rawStatus
                      ) ? (
                        <OrgDetailsForm
                          onSubmit={handleDetailsFormSubmit}
                          onCancel={
                            ["succeeded", "in progress"].includes(
                              onboarding?.rawStatus
                            )
                              ? null
                              : setupRetryMutation.mutate
                          }
                          initialValues={orgDetails}
                          error={orgMutation.error}
                          isFetching={
                            isPlaceholderOrg || isFetching || isOrgError
                          }
                          isSubmitting={orgMutation.isPending}
                          isCancelling={setupRetryMutation.isPending}
                        />
                      ) : (
                        <DetailsViewer
                          isLoading={isPlaceholderOrg || isFetching}
                          data={[
                            {
                              label: "Organization Name",
                              value: orgDetails?.name,
                            },
                            {
                              label: "Organization ID",
                              value: orgDetails?.id,
                            },
                            {
                              label: "Primary Contact",
                              value: orgDetails?.primaryContact?.name,
                            },
                            {
                              label: "Primary Email",
                              value: orgDetails?.primaryContact?.email,
                            },
                            {
                              label: "Domain",
                              value: orgDetails?.domain,
                            },
                            {
                              label: "Customer Segment",
                              value:
                                selectedCustomerSegment?.customerSegmentName,
                            },
                            ...(selectedCustomerSegment?.isMedicareLicensable
                              ? [
                                  {
                                    label: "Is Medicare QE Included?",
                                    value: orgDetails?.medicare_licensed
                                      ? "Yes"
                                      : "No",
                                  },
                                ]
                              : []),
                          ]}
                        />
                      )}
                    </motion.div>
                  }
                />

                <Route
                  path="general/segment-switcher"
                  element={
                    <motion.div
                      key="general/segment-switcher"
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      style={{ maxWidth: "450px", position: "relative" }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "-24px",
                          left: 0,
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        <Link
                          to={"../"}
                          state={{ from: "route" }}
                          style={{
                            fontSize: "14px",
                            fontFamily: "Inter",
                          }}
                        >
                          General
                        </Link>

                        <FontAwesomeIcon
                          icon={faChevronRight}
                          color="#3e3e3c"
                          fontSize="12px"
                        />

                        <div style={{ fontSize: "14px", color: "#3E3E3C" }}>
                          Segment Switcher
                        </div>
                      </div>
                      <Typography variant="h2">
                        Customer Segment Switcher
                      </Typography>
                      <Typography variant="p">
                        Changing your customer segment will result in a new
                        deployment of the product license.
                      </Typography>

                      <OrgSegmentSwitcher />
                    </motion.div>
                  }
                />

                <Route
                  path="bi"
                  element={
                    <motion.div
                      key="bi"
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      style={{ maxWidth: "500px" }}
                    >
                      <Typography variant="h2">User Licenses</Typography>

                      {role === ROLES.systemAdmin ? (
                        <OrgUserLicenseForm
                          onSubmit={handleUserLicenseFormSubmit}
                          submitLabel="Save Changes"
                          defaultValues={userLicense}
                          error={licenseMutation.error}
                          isFetching={
                            isPlaceholderOrg || isFetching || isOrgError
                          }
                          isSubmitting={licenseMutation.isPending}
                          disabledUntilTouched={true}
                        />
                      ) : (
                        <DetailsViewer
                          isLoading={isPlaceholderOrg || isFetching}
                          data={[
                            {
                              label: "User Limit",
                              value: userLicense?.licenses?.user_limit,
                            },
                            {
                              label: "BI Portal Explorer",
                              value:
                                userLicense?.licenses?.tableau_user_licenses
                                  ?.explorer_count,
                            },
                            {
                              label: "BI Portal Viewer",
                              value:
                                userLicense?.licenses?.tableau_user_licenses
                                  ?.viewer_count,
                            },
                            {
                              label: "BI Portal Creator",
                              value:
                                userLicense?.licenses?.tableau_user_licenses
                                  ?.creator_count,
                            },
                          ]}
                        />
                      )}
                    </motion.div>
                  }
                />

                <Route
                  path="users"
                  element={
                    <motion.div
                      key="user"
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <UserManagement />
                    </motion.div>
                  }
                />

                <Route
                  path="integrations"
                  element={
                    <motion.div
                      key="integrations"
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      style={{ marginBottom: "30vh", maxWidth: "500px" }}
                    >
                      <Typography variant="h2">Integrations</Typography>
                      <Typography variant="p">
                        Manage callback URLs for integrations.
                      </Typography>
                      <OrgIntegrationsForm
                        onSubmit={handleDetailsFormSubmit}
                        submitLabel="Save Changes"
                        initialValues={integrations}
                        error={orgMutation.error}
                        isFetching={
                          isPlaceholderOrg || isFetching || isOrgError
                        }
                        isSubmitting={orgMutation.isPending}
                      />
                    </motion.div>
                  }
                />

                <Route
                  path="preferences"
                  element={
                    <motion.div
                      key="preferences"
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      style={{ marginBottom: "30vh", maxWidth: "500px" }}
                    >
                      <Typography variant="h2">Preferences</Typography>
                      {role === ROLES.systemAdmin ? (
                        <>
                          <Typography variant="p">
                            Changes to the Default State will result in a
                            redeployment of the Product License.
                          </Typography>
                          <OrgPreferencesForm
                            onSubmit={handlePreferencesFormSubmit}
                            submitLabel="Save Changes"
                            defaultValues={preferences}
                            error={orgMutation.error}
                            isFetching={
                              isPlaceholderOrg || isFetching || isOrgError
                            }
                            isSubmitting={orgMutation.isPending}
                          />
                        </>
                      ) : (
                        <DetailsViewer
                          isLoading={isPlaceholderOrg || isFetching}
                          data={[
                            {
                              label: "Default State",
                              value:
                                preferences?.preferences?.bi_portal
                                  ?.default_states.length > 0
                                  ? preferences?.preferences?.bi_portal
                                      ?.default_states
                                  : "None",
                            },
                          ]}
                        />
                      )}
                    </motion.div>
                  }
                />

                <Route
                  path="product/*"
                  element={
                    <motion.div
                      key="product/*"
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      style={{
                        minWidth: "750px",
                        width: "fit-content",
                      }}
                    >
                      <OrgProductLicenseForm
                        submitLabel="Save Changes"
                        defaultValues={organization}
                        isFetching={
                          isPlaceholderOrg ||
                          isFetching ||
                          isRefetchingDeploymentStatus ||
                          isOrgError
                        }
                        licenseDeployment={deployment}
                        submitLicenseDeploymentRetry={
                          licenseDeployRetryMutation
                        }
                        refetchDeploymentStatus={refetchDeploymentStatus}
                        showCodeView={showCodeView}
                        customerSegment={{
                          segment: orgDetails?.customer_segment,
                          medicareLicensed: orgDetails?.medicare_licensed,
                          isPrOrg: selectedCustomerSegment?.isPrOrg,
                          prNumber: prNumberForPrCatalog,
                        }}
                      />
                    </motion.div>
                  }
                />
                <Route
                  path="configurations/*"
                  element={
                    <motion.div style={{}}>
                      <ManagedProviders />
                    </motion.div>
                  }
                />

                <Route
                  path="credentials"
                  element={
                    <motion.div
                      style={{ marginBottom: "30vh", maxWidth: "500px" }}
                    >
                      <Typography variant="h2">Data Credentials</Typography>

                      <CredentialNavSection
                        type="org"
                        entity={organization}
                        isLoading={isPlaceholderOrg || isFetching}
                        onMutationSuccess={() => {
                          queryClient.invalidateQueries({
                            queryKey: ["organizations"],
                          });
                        }}
                      />
                    </motion.div>
                  }
                />
              </Routes>
            </AnimatePresence>
          </Flex>
        </Flex>
      </Flex>
      <Modal
        visible={isModalOpen}
        dismiss={closeModal}
        title="JSON Viewer"
        style={{
          width: "60vw",
          marginTop: "5vh",
        }}
      >
        <JSONContainer>
          <ReactJsonView
            name={jsonRootName}
            src={modalContent}
            collapsed={4}
            displayObjectSize={false}
            displayDataTypes={false}
            quotesOnKeys={false}
            displayArrayKey={false}
          />
        </JSONContainer>
      </Modal>
    </div>
  );
};

export default UpdateOrg;

import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { faPeopleRoof } from "@fortawesome/pro-regular-svg-icons";

import Tabs from "core/components/Tabs";
import Tab from "core/components/Tab";
import PageHeader from "core/components/PageHeader";
import Field from "core/components/Field";
import { getOrganizations } from "modules/admin/actions";
import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import { useQuery } from "@tanstack/react-query";
import useCurrentUser from "core/hooks/useCurrentUser";
import { ROLES } from "core/constants";

const ManagedProviders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgId: organizationId } = useParams();
  const {
    currentUser: { role },
  } = useCurrentUser();

  const fetchQueryCall = useAuthenticatedCall(getOrganizations);
  const { data: organizations = [], isLoading } = useQuery({
    queryKey: ["organizations"],
    queryFn: fetchQueryCall,
  });

  const handleSelectOrg = (orgId) => {
    navigate(location.pathname.replace(organizationId, orgId));
  };

  return (
    <div style={{ position: "relative", padding: "30px 24px" }}>
      <PageHeader
        title="Managed Providers"
        icon={faPeopleRoof}
        iconBackgroundColor="#00C1F3"
        style={{
          justifyContent: "flex-start",
        }}
        PageActionsComponent={
          role === ROLES.systemAdmin ? (
            <div style={{ width: "300px" }}>
              <Field
                id="org-select"
                type="combobox"
                labelText=""
                hideError={true}
                placeholder="Select an organization"
                //  isDisabled = false,
                isRequired={true}
                showSkeleton={isLoading}
                isSearchable={true}
                value={organizationId}
                onChange={handleSelectOrg}
                options={organizations.map((org) => ({
                  label: `${org.name}`,
                  value: org.id,
                }))}
              />
            </div>
          ) : null
        }
      />
      <Tabs>
        <Tab to="aliases" first>
          Aliases
        </Tab>
        <Tab to="relationships" last>
          Relationships
        </Tab>
      </Tabs>
      <Outlet />
    </div>
  );
};

export default ManagedProviders;

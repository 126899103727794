import styled from "styled-components";

export const FormContainer = styled.div`
  display: block;
  width: 350px;
  margin-bottom: 15vh;
`;

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  gap: 0px;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const InputGroup = styled.div`
  width: 100%;

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}
`;

export const FieldContainer = styled.div`
  min-width: fit-content;
  width: 100%;
`;

export const FieldError = styled.div`
  font-family: "Inter";
  color: #ff2a00;
  font-size: 12px;
  text-align: right;
  padding: 2px;
  min-height: 22px;
  line-height: 14px;

  // Temporary inline control fix
  ${(props) =>
    props.isHidden &&
    `
    display: none;
  `}
`;

export const Label = styled.label`
  display: block;
  text-align: left;
  font-size: 0.75rem;
  font-weight: ${(props) => (props.normal ? "normal" : "600")};
  margin-bottom: 3px;
  font-family: "Inter";
  color: #000000;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
  `}
`;

export const Input = styled.input`
  height: 41px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dddbda;
  background-color: #ffffff;
  padding: 4px 10px;
  font-family: "Inter";
  font-size: 1rem;
  outline: none;

  &:focus {
    border: 1px solid #015fcc;
  }

  // Hacks autofill background style overrides
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
  }

  // Disabled styles
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;

  `}

  // Search related styles
  &[type="search"] {
    outline-offset: 0px;

    ::-webkit-search-cancel-button {
      cursor: po "Inter";
      font-size: 15px;
      margin-right: 5px;
      margin-bottom: 1px;

      ${(props) => props.cancelButtonStyles}
    }

    ::-webkit-textfield-decoration-container {
      gap: 10px;
    }
  }

  // Number type related styles
  &[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  // Small variant
  ${(props) =>
    props.variant === "small" &&
    `
    height: 25px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    padding-left: 10px;
  `}
`;

export const InputSkeletonContainer = styled.div`
  height: 41px;
  border-radius: 4px;
  border: 1px solid #dddbda;
  padding: 10px 8px;
  background-color: #ffffff;
  margin-bottom: 22px;

  span {
    border-radius: 2px;
    height: 100%;
    display: flex;
  }

  ${(props) =>
    props.size === "small" &&
    `
    height: 27px;
    padding: 0px;
    border: unset;

    span {
      display: block;
    }
  `}

  ${(props) =>
    props.type === "combobox" &&
    props.size !== "small" &&
    `
    height: 20px;
    padding: 9px 8px 10px 8px;
    margin-bottom: 0px;
  `}
`;

export const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  column-gap: 25px;
  row-gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const CheckboxInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const LabelTextWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Textarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #dddbda;
  border-radius: 4px;

  ${(props) =>
    props.disabled &&
    `
    background-color: #E4E4E4;
  `}
`;

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STATUS_COLORS, STATUS_BG_COLORS } from "./constants";

export const StatusIcon = styled(FontAwesomeIcon)`
  border-radius: 50%;
  border-width: 0px;
  width: 10px;
  height: 10px;
  padding: 5px;
  ${(props) =>
    STATUS_COLORS[props.variant] &&
    `
    color: ${STATUS_COLORS[props.variant]};
  `}

  ${(props) =>
    STATUS_BG_COLORS[props.variant] &&
    `
    background-color: ${STATUS_BG_COLORS[props.variant]};
  `}
`;

export const SideMenuContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  min-width: 225px;

  & > li {
    position: relative;
    list-style-type: none;
    padding: 0px;
    margin-bottom: 4px;
    font-family: "Inter";
    font-size: 18px;
    border-radius: 5px;
    height: 34px;

    &:hover {
      background-color: #e5d7e5;
    }

    &.active {
      h5 {
        font-weight: 600;
      }
    }
  }

  & > li:last-child {
    margin-bottom: 0px;
  }

  & > li > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #541299;
    padding: 7px 10px 7px 10px;
  }

  & > li > a:hover {
    text-decoration: none;
  }

  & > li > a > svg {
    margin-right: 8px;
  }
`;

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  getCustomerSegments,
  getOrganizationById,
  getProductCatalog,
  switchCustomerSegment,
} from "./actions";
import Form from "core/components/Form";
import Error from "core/components/Error";
import useForm from "core/hooks/useForm";
import Field from "core/components/Field";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import Typography from "core/components/Typography";
import Button from "core/components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { required, validate } from "common/validation";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useToaster from "core/hooks/useToaster";
import ConfirmModal from "core/components/ConfirmModal";
import { Input } from "core/components/Form/styles";

const formSchema = z.object({
  customer_segment: z.string().min(1),
  medicare_licensed: z.boolean(),
});

const OrgSegmentSwitcher = () => {
  const navigate = useNavigate();
  const { orgId: organizationId } = useParams();
  const { toaster } = useToaster();
  const queryClient = useQueryClient();

  const fetchOrganization = useAuthenticatedCall((req) =>
    getOrganizationById({ ...req, organizationId })
  );
  const fetchCatalog = useAuthenticatedCall((req) => {
    return getProductCatalog({ ...req });
  });
  const fetchCustomerSegments = useAuthenticatedCall(getCustomerSegments);

  const { data: organization } = useQuery({
    queryKey: ["organizations", organizationId],
    queryFn: fetchOrganization,
  });
  const { data: customerSegments, isLoading: isLoadingSegments } = useQuery({
    queryKey: ["customer-segments", null, {}],
    queryFn: fetchCustomerSegments,
  });
  const { data: catalog } = useQuery({
    queryKey: [
      "catalog",
      null,
      {
        include: ["models", "data", "fields", "field_values"],
        filters: [
          {
            catalog: "products",
            field: "is_license_required",
            operator: "=",
            value: true,
          },
        ],
      },
    ],
    queryFn: fetchCatalog,
    staleTime: Infinity,
  });

  // Must be memoized
  const initialValues = useMemo(() => {
    return {
      customer_segment: organization?.customer_segment ?? "",
      medicare_licensed: organization?.medicare_licensed ?? false,
    };
  }, [organization]);

  const { formValues, errors, isDisabled, onFieldChange } = useForm({
    placeholderValues: {
      customer_segment: "",
      medicare_licensed: false,
    },
    initialValues,
    formSchema,
  });

  const customerSegmentOptions = useMemo(() => {
    let obj = [];

    if (customerSegments && Object.keys(customerSegments).length > 0) {
      const customerSegmentKeys = Object.keys(customerSegments);
      for (let i = 0; i < customerSegmentKeys.length; i++) {
        const option = {
          value: customerSegmentKeys[i],
          label: Object.values(customerSegments).find(
            (item) => item.id === customerSegmentKeys[i]
          )?.name,
        };
        obj.push(option);
      }
    }

    return obj;
  }, [customerSegments]);

  const hasChangedSegment = useMemo(() => {
    return organization.customer_segment !== formValues.customer_segment;
  }, [organization, formValues]);

  const isMedicareLicensable = useMemo(() => {
    return (
      customerSegments[formValues.customer_segment]?.is_medicare_licensable ??
      false
    );
  }, [customerSegments, formValues]);

  const hasIncompatibleMedicare = useMemo(() => {
    const hasOrgLicensedMedicare = !!formValues.medicare_licensed;

    return hasOrgLicensedMedicare && !isMedicareLicensable;
  }, [isMedicareLicensable, formValues]);

  const incompatibleProducts = useMemo(() => {
    if (organization?.licenses?.products) {
      const licensedProducts = Object.entries(organization?.licenses?.products)
        .filter((entry) => {
          // eslint-disable-next-line no-unused-vars
          const [id, value] = entry;
          return value.enabled;
        })
        .map((entry) => {
          const [id] = entry;
          return id;
        });
      const licensableProducts =
        customerSegments[formValues.customer_segment]?.licensable_products ??
        [];

      return licensedProducts.filter((productId) => {
        const isLicensable = !licensableProducts.includes(productId);
        return isLicensable;
      });
    }

    return [];
  }, [customerSegments, formValues, organization]);

  const [removedProducts, setRemovedProducts] = useState([]);
  const allProductsCompatible = useMemo(() => {
    return incompatibleProducts.every((productId) =>
      removedProducts.includes(productId)
    );
  }, [incompatibleProducts, removedProducts]);

  const switchSegment = useAuthenticatedMutation((req) => {
    return switchCustomerSegment({
      ...req,
      organizationId,
      customer_segment: req.customer_segment,
      medicare_licensed: req.medicare_licensed,
      ...(req.licenses ? { licenses: req.licenses } : {}),
    });
  });

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirm, setConfirm] = useState("");
  const closeConfirm = (event) => {
    event?.preventDefault();
    setIsConfirmOpen(false);
    setConfirm("");
  };
  const segmentSwitchMutation = useMutation({
    mutationFn: switchSegment,
    onSuccess: async (data, variables, context) => {
      // Close modal
      closeConfirm();

      // Invalidate queries for refetching
      queryClient.invalidateQueries({
        queryKey: ["organization-deployment", organizationId],
      });
      queryClient.invalidateQueries({
        queryKey: ["organization-onboarding", organizationId],
      });
      queryClient.invalidateQueries({ queryKey: ["organizations"] });
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId],
      });

      // Notify
      toaster.success({
        message: "Customer Segment switched successfully!",
        duration: 1500,
        onDismiss: () => {
          // Back to General form
          navigate("../");
        },
      });
    },
    onError: async (error, variables, context) => {
      console.error("[segmentSwitchMutation] [onError]", {
        error,
        variables,
        context,
      });
    },
    onSettled: () => {},
  });

  const handleSubmit = (event) => {
    if (incompatibleProducts.length > 0) {
      const updatedProducts = incompatibleProducts.reduce(
        (productMap, productId) => {
          productMap[productId] = {
            enabled: false,
          };

          return productMap;
        },
        {}
      );

      segmentSwitchMutation.mutate({
        customer_segment: formValues.customer_segment,
        medicare_licensed: formValues.medicare_licensed,
        licenses: {
          products: updatedProducts,
        },
      });
    } else {
      segmentSwitchMutation.mutate({
        customer_segment: formValues.customer_segment,
        medicare_licensed: formValues.medicare_licensed,
      });
    }
  };

  return (
    <Form
      onSubmit={() => setIsConfirmOpen(true)}
      submitLabel="Save Changes"
      onCancel={() => navigate("../")}
      cancelLabel="Cancel"
      isFetching={isLoadingSegments}
      disabled={
        isDisabled ||
        !hasChangedSegment ||
        hasIncompatibleMedicare ||
        !allProductsCompatible ||
        segmentSwitchMutation.isPending
      }
    >
      <Error>{segmentSwitchMutation.error}</Error>
      <Field
        type="combobox"
        id="customer_segment"
        labelText="Customer Segment"
        showSkeleton={isLoadingSegments}
        isRequired={true}
        value={formValues.customer_segment}
        error={errors.customer_segment}
        onChange={(val) => {
          setRemovedProducts([]);
          onFieldChange("customer_segment")(val);
        }}
        validator={validate(required())}
        options={customerSegmentOptions}
      />
      <Field
        type="combobox"
        id="medicare_licensed"
        labelText="Medicare"
        showSkeleton={isLoadingSegments}
        isRequired={true}
        value={String(formValues.medicare_licensed)}
        error={
          hasIncompatibleMedicare
            ? "Selected segment is incompatible with medicare."
            : errors.medicare_licensed
        }
        onChange={(val) => {
          if (val === "false") {
            onFieldChange("medicare_licensed")(false);
          } else {
            onFieldChange("medicare_licensed")(true);
          }
        }}
        validator={validate(required())}
        options={[
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ]}
      />

      {incompatibleProducts.length > 0 ? (
        <div style={{ marginTop: "8px", marginBottom: "20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                marginBottom: "8px",
              }}
            >
              <Typography
                variant="h5"
                noMargin
                style={{ color: allProductsCompatible ? "#29824f" : "#BA351C" }}
              >
                Products
              </Typography>
              <FontAwesomeIcon
                icon={
                  allProductsCompatible ? faCircleCheck : faCircleExclamation
                }
                fontSize={20}
                style={{
                  color: allProductsCompatible ? "#29824f" : "#BA351C",
                  marginBottom: "1px",
                }}
              />
            </div>
            <Button
              size="small"
              variant="text"
              style={{ opacity: allProductsCompatible ? 0 : 1 }}
              onClick={(event) => {
                event.preventDefault();
                setRemovedProducts(incompatibleProducts);
              }}
            >
              Remove All
            </Button>
          </div>
          <Typography variant="p" style={{ marginBottom: "12px" }}>
            The following products cannot be licensed with the selected customer
            segment. Please remove each one.
          </Typography>

          {incompatibleProducts.map((incompatibleProduct) => {
            const productName =
              catalog[incompatibleProduct]?.name ?? incompatibleProduct;
            const isRemoved = removedProducts.includes(incompatibleProduct);

            return (
              <div
                key={incompatibleProduct}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    opacity: isRemoved ? 0.8 : 1,
                    textDecoration: isRemoved ? "line-through" : "none",
                  }}
                >
                  {productName}
                </span>
                <Button
                  size="small"
                  variant="text"
                  style={{ opacity: isRemoved ? 0 : 1 }}
                  onClick={(event) => {
                    event.preventDefault();

                    setRemovedProducts((previous) =>
                      previous.concat(incompatibleProduct)
                    );
                  }}
                >
                  Remove
                </Button>
              </div>
            );
          })}
        </div>
      ) : null}

      <ConfirmModal
        visible={isConfirmOpen}
        onYes={handleSubmit}
        onNo={closeConfirm}
        dismiss={closeConfirm}
        noLabel="Cancel"
        yesLabel="I understand the consequences, proceed."
        disabled={confirm !== organization?.id}
        isSubmitting={segmentSwitchMutation.isPending}
        title="Switch Customer Segment"
        width="600px"
      >
        <Error>{segmentSwitchMutation.error}</Error>
        <Typography variant="p">
          Switching this organization&rsquo;s customer segment will result in a
          new deployment of the product license. This change cannot be undone
          once it has started. This is your last chance to save your job at{" "}
          {organization.name}.
        </Typography>
        <Typography variant="p">
          Please type <b>{organization.id}</b> to confirm.
        </Typography>
        <div style={{ paddingBottom: "15px" }}>
          <Input value={confirm} onChange={(e) => setConfirm(e.target.value)} />
        </div>
      </ConfirmModal>
    </Form>
  );
};

export default OrgSegmentSwitcher;

import { Tooltip } from "react-tooltip";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";

import { TinyIcon } from "core/styles";
import { LabelRequired, LabelText } from "./styles";
import Flex from "../Flex";

const Label = (props) => {
  const {
    htmlFor,
    id,
    text,
    position,
    variant,
    hint,
    isDisabled,
    isReadOnly,
    isRequired,
    children,
    clickable = false,
    labelStyle,
  } = props;

  const hintComponent = hint && (
    <>
      <TinyIcon
        icon={faCircleInfo}
        data-tooltip-id={`${id}labelicon`}
        style={{ display: "inline" }}
      />
      <Tooltip
        id={`${id}labelicon`}
        html={typeof hint === "string" ? hint : null}
        content={typeof hint !== "string" ? hint : null}
        positionStrategy="fixed"
        place="right"
        style={{ zIndex: "1000" }}
        clickable={clickable}
      />
    </>
  );

  // Matt: This is incorrect, label should wrap inputs
  const textComponent = text && variant !== "small" && (
    <LabelText
      htmlFor={htmlFor}
      disabled={isDisabled}
      readOnly={isReadOnly}
      style={{ height: labelStyle?.height || "unset" }}
    >
      {text}
      {isRequired ? <LabelRequired>*</LabelRequired> : null}
      &nbsp;
      {position === "top" && hintComponent}
    </LabelText>
  );

  return (
    <Flex
      width="100%"
      gap="4px"
      alignItems={position === "left" ? "baseline" : null}
      direction={position === "left" ? "row" : "column"}
    >
      {textComponent}
      {children}
      {position === "left" && hint && <div>{hintComponent}</div>}
    </Flex>
  );
};

export default Label;

import { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ErrorBoundary } from "@datadog/browser-rum-react";

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import useCurrentUser from "core/hooks/useCurrentUser";
import useToaster from "core/hooks/useToaster";
import Search from "core/components/Search";
import PageHeader from "core/components/PageHeader";
import TableErrorFallback from "core/components/Table/TableErrorFallback";
import { Row } from "core/styles";
import { getUsers } from "modules/admin/actions";
import UserTable from "modules/admin/UserTable";
import Button from "core/components/Button";
import { ROLES } from "core/constants";
import useNavigation from "core/hooks/useNavigation";

const UserManagement = () => {
  const navigate = useNavigate();
  const { toaster } = useToaster();
  const params = useParams();
  const { orgId: organizationId } = params;
  const {
    currentUser: { role, organizationId: userOrgId },
  } = useCurrentUser();

  const { userSearchValue, setUserSearchValue } = useNavigation();
  const handleSearchChange = (event) => {
    setUserSearchValue(event.target.value);
  };
  const location = useLocation();

  const fetchUsers = useAuthenticatedCall(getUsers);
  const queryKey = ["users", organizationId];
  const {
    isLoading,
    data: users,
    fetchStatus,
    isError: isUsersError,
    error: usersError,
  } = useQuery({
    queryKey,
    queryFn: fetchUsers,
  });

  useEffect(() => {
    if (isUsersError) {
      if (Array.isArray(usersError)) {
        toaster.error({ message: usersError[0] });
      } else {
        toaster.error({ message: usersError.message });
      }
    }
  }, [isUsersError, usersError, toaster]);

  useEffect(() => {
    const pathname = location.pathname; // this gives me current Url's pathname
    localStorage.setItem("pageUrl", pathname);
  }, [location.pathname]);

  const canUpdateUsers =
    role === ROLES.systemAdmin ||
    (role === ROLES.orgAdmin && userOrgId === organizationId);

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PageHeader
        title="Users"
        PageActionsComponent={
          <Row
            style={{
              width: "fit-content",
              flex: 1,
              justifyContent: "flex-end",
              alignSelf: "flex-end",
              gap: "15px",
            }}
          >
            <Search value={userSearchValue} onChange={handleSearchChange} />
            {canUpdateUsers && (
              <Button
                width="auto"
                onClick={() =>
                  navigate(
                    `/admin/org-management/${organizationId}/users/create`,
                    {
                      state: {
                        from:
                          userOrgId === organizationId
                            ? "MyOrganization"
                            : "OrgUsers",
                        orgId: organizationId,
                      },
                    }
                  )
                }
              >
                Create User
              </Button>
            )}
          </Row>
        }
      />
      <ErrorBoundary onError={console.error} fallback={TableErrorFallback}>
        <UserTable
          data={users || []}
          isRefreshing={fetchStatus === "fetching"}
          isLoading={isLoading}
          canUpdate={canUpdateUsers}
          searchFilter={{
            searchValue: userSearchValue,
            columns: ["name", "email"],
          }}
          userRole={role}
          style={{
            flexGrow: "1",
          }}
        />
      </ErrorBoundary>
    </div>
  );
};

export default UserManagement;

import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCaretRight,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import {
  NextButton,
  PageNumberInput,
  PaginationContainer,
  PreviousButton,
} from "./styles";
import useNavigation from "core/hooks/useNavigation";

const Pagination = (props) => {
  const { tableInstance, isRefreshing, pageKey } = props;
  const {
    getState,
    previousPage,
    nextPage,
    setPageIndex,
    getCanPreviousPage,
    getCanNextPage,
    getPageCount,
  } = tableInstance;
  const currentPage = getState().pagination.pageIndex + 1;
  const { setCurrentUserPageNumber, setCurrentOrgPageNumber } = useNavigation();

  useEffect(() => {
    if (pageKey === "Org") {
      setCurrentOrgPageNumber(currentPage - 1);
    } else if (pageKey === "Users") {
      setCurrentUserPageNumber(currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageKey]);

  return (
    <PaginationContainer>
      {isRefreshing ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faCircleNotch} spin />
          <span style={{ marginLeft: "8px" }}>Refreshing</span>
        </div>
      ) : (
        <div>Displaying Page</div>
      )}

      <PreviousButton onClick={previousPage} disabled={!getCanPreviousPage()}>
        <FontAwesomeIcon icon={faCaretLeft} size="lg" />
      </PreviousButton>
      <div>
        <PageNumberInput
          type="number"
          value={currentPage}
          onChange={(event) => {
            const page = event.target.value
              ? Number(event.target.value) - 1
              : 0;
            setPageIndex(page);
          }}
        />
      </div>
      <div>
        of <span className="total-pages">{getPageCount()}</span>
      </div>
      <NextButton onClick={nextPage} disabled={!getCanNextPage()}>
        <FontAwesomeIcon icon={faCaretRight} size="lg" />
      </NextButton>
    </PaginationContainer>
  );
};

export default Pagination;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextWithMark } from "./styles";
import { formatSpecialCharacters } from "common/utilities";

const commonStyle = {
  flexGrow: 1,
  overflowX: "clip",
  textOverflow: "ellipsis",
};

const createMixedContentSegments = (contentString = "", matchString = "") => {
  // Split text into segments that should and shouldn't be highlighted
  const segments = [];
  let lastIndex = 0;
  const regex = new RegExp(matchString, "gi");
  let match;

  while ((match = regex.exec(contentString)) !== null) {
    // Add non-matching text before this match
    if (match.index > lastIndex) {
      segments.push({
        text: contentString.slice(lastIndex, match.index),
        highlight: false,
      });
    }
    // Add matching text
    segments.push({
      text: match[0],
      highlight: true,
    });
    lastIndex = regex.lastIndex;
  }
  // Add remaining text after last match
  if (lastIndex < contentString.length) {
    segments.push({
      text: contentString.slice(lastIndex),
      highlight: false,
    });
  }

  return segments;
};

const MatchableText = ({
  children = "",
  matchString = "",
  placeholder = "",
}) => {
  if (!children) {
    return <i style={commonStyle}>{placeholder}</i>;
  }

  if (!matchString) {
    return (
      <span title={children} style={commonStyle}>
        {children}
      </span>
    );
  }

  const formattedMatchString = formatSpecialCharacters(matchString);
  const segments = createMixedContentSegments(children, formattedMatchString);

  return (
    <TextWithMark title={children}>
      {segments.map((segment, index) =>
        segment.highlight ? (
          <mark key={index}>{segment.text}</mark>
        ) : (
          <span key={index}>{segment.text}</span>
        )
      )}
    </TextWithMark>
  );
};

export const SearchableTableCell = ({
  searchConfig,
  columnId,
  placeholder = "",
  children,
  ...rest
}) => {
  const isComponentInDefaultMode = !!rest.table;
  const config = isComponentInDefaultMode
    ? rest.table.options.meta.searchConfig
    : searchConfig;
  const id = isComponentInDefaultMode ? rest.column.id : columnId;

  const isColumnSearchable = !!config.columns?.includes(id);
  const matchString = isColumnSearchable ? config.searchValue : "";

  if (isComponentInDefaultMode) {
    return (
      <MatchableText matchString={matchString} placeholder={placeholder}>
        {rest.getValue()}
      </MatchableText>
    );
  }

  return (
    <MatchableText matchString={matchString} placeholder={placeholder}>
      {children}
    </MatchableText>
  );
};

export const TextWithIconTableCell = ({
  position = "left",
  icon,
  primaryColor,
  iconBackgroundColor,
  children,
}) => {
  const baseIconStyles = {
    height: "16px",
    width: "20px",
    color: primaryColor,
    padding: "3px",
    flexShrink: 0,
    flexGrow: 0,
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {position === "left" && (
        <FontAwesomeIcon
          icon={icon}
          style={{
            ...baseIconStyles,
            marginRight: "10px",
          }}
        />
      )}
      {children}
      {position === "right" && (
        <FontAwesomeIcon
          icon={icon}
          style={{
            ...baseIconStyles,
            marginLeft: "10px",
          }}
        />
      )}
    </div>
  );
};

export const IconTableCell = ({
  icon,
  iconAlt,
  primaryColor,
  secondaryColor,
  styles,
}) => {
  const baseIconStyles = {
    aspectRatio: "17.65 / 20",
    height: "16px",
    padding: "3px",
    borderRadius: "4px",
    "--fa-primary-color": primaryColor || "default",
    "--fa-secondary-color": secondaryColor || primaryColor || "default",
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
      title={iconAlt}
    >
      <FontAwesomeIcon
        icon={icon}
        alt={iconAlt}
        style={{
          ...baseIconStyles,
          ...styles,
        }}
      />
    </div>
  );
};

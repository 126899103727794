import DOMPurify from "dompurify";

export const required = () => (value) => !value ? "Required" : false;
export const objectOrArrayRequired = () => (objectOrArrayValue) =>
  Object.keys(objectOrArrayValue).length === 0 ? "Required" : false;
export const minLength = (min) => (value) =>
  value?.length < min ? `Minimum ${min} length` : false;
export const maxLength = (max) => (value) =>
  value?.length > max ? `Maximum ${max} length` : false;
export const matches = (matchingValue) => (value) =>
  matchingValue !== value ? "Must match" : false;
export const domain = () => (value) => {
  const areAnyInvalid = value.some((v) => {
    return !/^[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v);
  });

  return areAnyInvalid ? "Must be a valid domain name" : false;
};
export const noDuplicateArrayItems = () => (arrayValue) => {
  const areAnyDuplicates = arrayValue.some((v) => {
    return arrayValue.filter((av) => av === v).length > 1;
  });

  return areAnyDuplicates ? "Must not contain duplicates" : false;
};

export const email = () => (value) =>
  value && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
    ? "Must be a valid email address"
    : false;
export const password = () => minLength(8);
export const phone = () => (value) =>
  !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(value)
    ? "Must be a valid phone number"
    : false;
export const lowercase = () => (value) =>
  value !== value.toLowerCase() ? "Must be lower case" : false;
export const notEquals =
  (...reservedWords) =>
  (value) => {
    for (const reservedWord of reservedWords) {
      if (reservedWord === value) {
        return `${reservedWord} is a reserved word`;
      }
    }
    return false;
  };

export const lettersAndNumbers = () => (value) =>
  !/^[a-zA-Z0-9]*$/.test(value)
    ? "Must only contain letters and numbers"
    : false;
export const startsWithLetter = () => (value) =>
  !/^[a-zA-Z]+/.test(value.substr(0, 1)) ? "Must start with a letter" : false;

export const doesNotMatchCurrentPasswordError = "Must be a new password";
export const doesNotMatchCurrentPassword = (oldPassword) => (newPassword) => {
  return oldPassword === newPassword ? doesNotMatchCurrentPasswordError : false;
};

export const newAndConfirmPasswordsMatchError = "Passwords must match";
export const newAndConfirmPasswordsMatch =
  (newPassword) => (confirmPassword) => {
    return newPassword !== confirmPassword
      ? newAndConfirmPasswordsMatchError
      : false;
  };

export const validate =
  (...validators) =>
  (value, field) => {
    // From new Field component we will not get field arg.
    let error;

    for (const validator of validators) {
      error = validator(value, field);
      if (error) {
        return error;
      }
    }

    return false;
  };

export const XSSValidationError =
  "Must be a valid text, html tags are not allowed.";
export const XSSValidation = (str) => {
  if (!str) return false;
  const trimmedStr = String(str).trim();
  const clean = DOMPurify.sanitize(trimmedStr, {
    ALLOWED_TAGS: [],
    ALLOWED_ATTR: [],
  });
  return clean !== trimmedStr ? XSSValidationError : false;
};

// This is passed as a superRefine method to the newPassword field
export const newPasswordZodValidator = (value, context) => {
  if (value.length < 15) {
    context.addIssue({
      message: "Must contain at least 15 characters.",
    });
  }

  const regex = new RegExp(/([\s\S])\1\1\1/); // api regex

  if (regex.test(value)) {
    context.addIssue({
      message: "Must not contain characters repeating 4 or more times.",
    });
  }

  const blacklistPasswords = ["password", "p@ssword", "pa$$word", "p@$$word"];
  const containsBlacklistedPassword = blacklistPasswords.some(
    (blackListedPassword) => {
      return value.toLowerCase().includes(blackListedPassword);
    }
  );

  if (containsBlacklistedPassword) {
    context.addIssue({
      message: "Must not contain the word 'password' in any form.",
    });
  }
};

export const invalidUrlError = "Must contain valid urls";

const protocolPattern = "^(https?:\\/\\/)";
const ipAddressPattern = "((\\d{1,3}\\.){3}\\d{1,3}))";
const portPattern = "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*";
const queryStringPattern = "(\\?[;&a-z\\d%_.~+=-]*)?";
const fragmentLocatorPattern = "(\\#[-a-z\\d_]*)?$";

export const isUrlValid = (url) => {
  const domainName = url.split("//")[1];
  const validPattern = new RegExp("^(www\\.)", "i");
  const isWebSubDomain = !!validPattern.test(domainName); // if isWebSubDomain is true, domain name starts with www

  let domainPattern; // regex will check for domain name OR IP (v4) address in the url
  if (isWebSubDomain) {
    domainPattern = "(((www\\.)+([a-z-\\d]+[\\.]))+[a-z]{2,}|";
  } else {
    domainPattern = "((([a-z\\d]+(-[a-z-\\d]+)*)\\.)+[a-z]{2,}|";
  }

  const validUrlPattern = new RegExp(
    protocolPattern +
      domainPattern +
      ipAddressPattern +
      portPattern +
      queryStringPattern +
      fragmentLocatorPattern,
    "i"
  );

  return !!validUrlPattern.test(url);
};

export const validUrls = () => (url) => {
  let trimmedUrl, isValid;
  if (Array.isArray(url)) {
    isValid = url.every((val) => {
      trimmedUrl = String(val).trim();
      return isUrlValid(trimmedUrl);
    });
  } else {
    trimmedUrl = String(url).trim();
    isValid = isUrlValid(trimmedUrl);
  }

  return Array.isArray(url) ? (!isValid ? invalidUrlError : false) : isValid;
};

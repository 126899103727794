import { useMemo } from "react";

import useBulkPaste from "./useBulkPaste";
import BulkPasteValidatorTable from "./BulkPasteValidatorTable";
import {
  ModalButtonContainer,
  ModalColumn,
  ModalHeader,
  ModalText,
} from "./styles";
import Button from "../Button";

const BulkPasteValidator = () => {
  const { label, invalidValues, hideModal, saveValidator, editModeIndex } =
    useBulkPaste();

  const isSaveValidatorDisabled = useMemo(() => {
    // Disabled if currently editing a value
    return editModeIndex !== null;
  }, [editModeIndex]);

  return (
    <ModalColumn>
      <ModalHeader>Validate {label}</ModalHeader>

      <ModalText>
        There were {invalidValues.length} invalid {label.toLowerCase()}. Please
        correct the errors below, uncorrected {label.toLowerCase()} will not be
        added.
      </ModalText>

      <BulkPasteValidatorTable />

      <ModalButtonContainer>
        <Button
          variant="outlined"
          onClick={hideModal}
          data-cy="modal-cancel-button"
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={saveValidator}
          disabled={isSaveValidatorDisabled}
          data-cy="modal-submit-button"
        >
          Add {label}
        </Button>
      </ModalButtonContainer>
    </ModalColumn>
  );
};

export default BulkPasteValidator;

import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DocumentTitle from "react-document-title";

import { passwordReset } from "modules/auth/actions";
import useFlash from "core/hooks/useFlash";
import UnifiedAuthPageWrapper from "core/components/UnifiedAuthPageWrapper";
import Link from "core/components/Link";
import { Fieldset } from "core/components/Form/styles";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin");
  const { setSuccess } = useFlash();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const formData = new FormData(event.target);
      const formProps = Object.fromEntries(formData);
      const result = await passwordReset({ ...formProps, origin });

      setSuccess(result.message || "Success");
      navigate("/");
    } catch (error) {
      console.error(error);
      setError(error);
    }

    setIsLoading(false);
  };

  return (
    <UnifiedAuthPageWrapper>
      <DocumentTitle title={"Populi | Forgot Password"} />
      <div className="form-wrapper">
        <div className="main-header">
          <h1 className="main-header__heading">Forgot your password?</h1>
          <p>
            Enter your email address and we&rsquo;ll send you a link to reset
            your password.
          </p>
        </div>

        <form id="login-form" onSubmit={handleSubmit}>
          <Fieldset disabled={isLoading}>
            <div className="form-section">
              <div className="input__header">
                <label htmlFor="email" className="input__label">
                  Email
                </label>
                <span id="email-error" className="input-error">
                  Email is required
                </span>
              </div>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Enter email"
                autoComplete="off"
                required
                autoFocus
              />
            </div>

            {error && (
              <div id="form-error" className="form-error is-errored">
                {error}
              </div>
            )}

            <div className="form-section">
              <button className="button--primary">Send reset link</button>
            </div>

            <div className="form-extras">
              <div className="help-links">
                <Link to="/">Back to login</Link>
              </div>
            </div>
          </Fieldset>
        </form>
      </div>
    </UnifiedAuthPageWrapper>
  );
};

export default ForgotPassword;

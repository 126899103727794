import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
// import useMeasure from "react-use-measure";
import { animated, useSpring, useTransition } from "react-spring";
import Skeleton from "react-loading-skeleton";
import ReactSelect, { components } from "react-select";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCheck,
  faCheckSquare,
  faCircleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

import {
  FieldError,
  InputGroup,
  InputSkeletonContainer,
  Label,
} from "core/components/Form/styles";
import Chip from "core/components/Chip";

const StyledControl = styled(components.Control)`
  gap: 5px;
  background-color: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 4px;
  padding-top: 9px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 16px;

  ${(props) =>
    props.selectProps.size === "small" &&
    `
    padding-top: 1px;
    padding-bottom: 0px;
    padding-left: 6px;
    padding-right: 10px;
    min-height: 27px !important;
  `}

  ${(props) =>
    props.selectProps.errorMessage &&
    `
    border-color: #BA351C;
  `}

  ${(props) =>
    (props.menuIsOpen || props.isFocused) &&
    `
      border-color: #015fcc;
      min-height: 20px;

      &:hover {
        border-color: "#015fcc",
      }
  `}



  ${(props) =>
    props.isDisabled &&
    `
    background-color: #E4E4E4;
  `}
`;

const StyledPlaceholder = styled(components.Placeholder)`
  color: #9f9f9f;
  font-size: 16px;
  font-family: Inter;

  ${(props) =>
    props.isFocused &&
    `
    display: none;
  `}}

  ${(props) =>
    props.selectProps.size === "small" &&
    `
      font-size: 12px;
  `}}

      ${(props) =>
        props.selectProps.size === "large" &&
        `
      height: 20px;
  `}}
`;

const StyledInput = styled(components.Input)`
  font-family: Inter;
`;

const CustomInput = (props) => {
  return (
    <StyledInput {...props} key="input">
      {props.children}
    </StyledInput>
  );
};

const StyledNoOptionsMessage = styled(components.NoOptionsMessage)`
  font-family: Inter;
  padding: 10px;
`;

const CustomNoOptionsMessage = (props) => {
  return (
    <StyledNoOptionsMessage {...props}>No matches found</StyledNoOptionsMessage>
  );
};

const StyledSingleValue = styled(components.SingleValue)`
  color: #3e3e3c;
  font-size: 16px;
  font-family: Inter;

  ${(props) =>
    props.isDisabled &&
    `
    background-color: #3E3E3C;
  `}

  ${(props) =>
    props.selectProps.size === "small" &&
    `
    font-size: 12px;
  `}

      ${(props) =>
    props.selectProps.size === "large" &&
    `
      height: 20px;
  `}
`;

const StyledIndicatorsContainer = styled(components.IndicatorsContainer)`
  align-self: start !important;
  align-items: center !important;
  margin-top: 1px;

  ${(props) =>
    props.selectProps.size === "small" &&
    `
    svg {
      margin-top: 2px;
    }
  `}
`;

const CustomIndicatorsContainer = (props) => {
  return (
    <StyledIndicatorsContainer {...props}>
      {props.selectProps.errorMessage && (
        <FontAwesomeIcon
          icon={faCircleExclamation}
          style={{ marginRight: "12px", marginTop: "2px", color: "#BA351C" }}
        />
      )}
      {props.children}
    </StyledIndicatorsContainer>
  );
};

const StyledDropdownIndicator = styled(components.DropdownIndicator)`
  color: #706e6b;
  cursor: pointer !important;
`;

const CustomDropdownIndicator = (props) => {
  return (
    <StyledDropdownIndicator {...props}>
      <FontAwesomeIcon icon={faCaretDown} />
    </StyledDropdownIndicator>
  );
};

const StyledClearIndicator = styled(components.ClearIndicator)`
  color: #706e6b;
  cursor: pointer !important;
  margin-left: 9px;
  margin-right: 9px;
  margin-bottom: -1px;

  svg {
    padding: 1px 6px;
    border-radius: 100%;

    &:hover {
      background-color: #f5f2fc;
    }
  }

  ${(props) =>
    props.selectProps.size === "small" &&
    `
    margin-left: 5px;
    margin-right: 5px;

    svg {
      margin-top: 0px;
    }
  `}
`;

const CustomClearIndicator = (props) => {
  return (
    <StyledClearIndicator {...props}>
      <FontAwesomeIcon icon={faXmark} />
    </StyledClearIndicator>
  );
};

const StyledIndicatorSeparator = styled(components.IndicatorSeparator)`
  display: none;
`;

const StyledMenu = styled(components.Menu)`
  padding-top: 3px;
  padding-bottom: 4px;
  background-color: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 4px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
  margin-top: 2px;
`;

const CustomStyledMenu = (props) => {
  return (
    <StyledMenu
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        "data-cy": "react-select-menu-testid",
      })}
    >
      {props.children}
    </StyledMenu>
  );
};

const StyledMenuList = styled(components.MenuList)``;

const CustomMenuList = (props) => {
  return <StyledMenuList {...props}>{props.children}</StyledMenuList>;
};

const StyledOption = styled.div`
  display: flex !important;
  font-size: 12px !important;
  gap: 8px;
  align-items: center;
  color: #2b2826;
  font-family: Inter;
  background-color: #ffffff;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer !important;
  font-weight: unset;

  svg {
    display: block;
    color: #007eac;
    height: 18px;
    width: 18px;
    box-sizing: border-box !important;
    align-self: flex-start;
  }

  &:hover {
    background-color: #f5f2fc;
  }

  ${(props) =>
    props.isFocused &&
    `
    background-color: #F5F2FC;
  `}

  ${(props) =>
    props.isSelected &&
    `
    // font-weight: bold;
    // background-color: #c7d8f0;

      svg {
        visibility: visible;
      }

      &:hover {
        // background-color: #c7d8f0;
      }
  `}

    ${(props) =>
    !props.isSelected &&
    `
    padding-top: 10px;

  `}

  ${(props) =>
    props.isGroupOption &&
    `
    font-weight: bold;
  `}

  ${(props) =>
    props.isChildOption &&
    `
    font-weight: normal;
    padding-left: 36px;
  `}

  ${(props) =>
    props.isChildOption &&
    !props.isMulti &&
    `
    padding-left: 12px;
  `}

  ${(props) =>
    props.selectProps.size === "small" &&
    `
    padding-top: 7px;
    padding-bottom: 7px;

    svg {
      height: 14px;
      width: 14px;
    }
  `}
`;

const CustomOption = (props) => {
  const {
    data,
    label,
    innerProps,
    isDisabled,
    isFocused,
    isSelected: baseIsSelected,
    isMulti,
    selectProps,
  } = props;

  const { isAllSelected, isGrouped, onGroupClick, value } = selectProps;
  const isSelectAll = isMulti && data.value === "__ALL";
  const isGroup = !!data.children;
  const isSelected = isSelectAll
    ? isAllSelected
    : isGroup
    ? data.children.every(
        (option) =>
          Array.isArray(value) &&
          !!value.find((item) => item.value === option.value)
      )
    : baseIsSelected;

  const selectedIcon = isMulti ? faCheckSquare : faCheck;
  const hasIcon = isSelected;

  const baseProps = {
    ...innerProps,
    isMulti,
    isDisabled,
    isFocused,
    isSelected,
    isGroupOption: isGroup && !isSelectAll,
    isChildOption: !isGroup && !isSelectAll && isGrouped,
    onClick: isSelectAll
      ? data.onClick
      : isGroup
      ? isMulti
        ? () => onGroupClick(data.groupId, isSelected)
        : null
      : innerProps.onClick,
    selectProps,
  };

  return (
    <StyledOption {...baseProps}>
      {hasIcon && <FontAwesomeIcon icon={selectedIcon} />}
      {!hasIcon && !isMulti && !isGroup && (
        <ItemSpacer iconProps={selectProps} />
      )}
      {!hasIcon && isMulti && <EmptyCheckbox />}
      <div
        style={{
          marginLeft: !isSelected ? 1 : "unset",
          marginTop: isSelected ? 1 : "unset",
        }}
      >
        {label}
      </div>
    </StyledOption>
  );
};

const ItemSpacer = styled.div`
  align-self: flex-start;
  flex-shrink: 0;

  ${(props) =>
    props?.iconProps?.size === "small" &&
    `
      height: 14px;
      width: 14px;
  `}

  ${(props) =>
    props?.iconProps?.size === "large" &&
    `
      height: 17px;
      width: 17px;
  `}
`;

const EmptyCheckbox = styled(ItemSpacer)`
  align-self: flex-start;
  flex-shrink: 0;
  border: 1px solid #dddbda;
  border-radius: 3px;
  background-color: #ffffff;
  width: 15px;
  height: 15px;
`;

const StyledValueContainer = styled(components.ValueContainer)`
  display: flex !important;
  align-items: center !important;
  flex-wrap: ${(props) => (props.isMulti ? "wrap" : "nowrap")} !important;
  transition: height 300ms cubic-bezier(0.87, 0, 0.13, 1);
  overflow: hidden;
  min-height: 16px;

  ${(props) =>
    props.isMulti &&
    props.size === "large" &&
    `
      min-height: 100px;
  `}

  ${(props) =>
    (props.menuIsOpen || props.isFocused) &&
    `
      min-height: 20px;
  `}
`;

const AnimatedMultiValueList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  min-height: 20px;

  ${(props) =>
    props.size === "small" &&
    `
      gap: 3px;

  `}
`;

const SecondaryButton = styled.div`
  font-size: 12px;
  font-family: Inter;
  text-align: center;
  color: #541299;
  padding: 2px 6px;
  padding-top: 2px;
  border: 1px solid #dddbda;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #f0e9f0;
    border-color: #f0e9f0;
  }
`;

const CustomMultiValue = (props) => {
  return (
    <Chip
      text={props.data.label}
      variant="actionable"
      onDismiss={props.removeProps.onClick}
      size={props.selectProps.size}
    />
  );
};

const CustomValueContainer = (props) => {
  const { children, isMulti, options, ...rest } = props;
  const { multiValueMaxDisplayCount, size } = rest.selectProps;
  const [value, input] = children;
  const isValuePlaceholder = !!value && value?.key === "placeholder";
  const isValueSelectedItems = value && !isValuePlaceholder;
  const valuesExceedMax =
    isValueSelectedItems && value?.length > multiValueMaxDisplayCount;

  const [isValueListExpanded, setIsValueListExpanded] = useState(false);

  const expandList = () => {
    setIsValueListExpanded(true);
  };

  const collapseList = () => {
    setIsValueListExpanded(false);
  };

  const visibleValues = useMemo(() => {
    if (isValueSelectedItems && valuesExceedMax && !isValueListExpanded) {
      return value.filter((_, index) => index < multiValueMaxDisplayCount);
    }

    // Otherwise return all
    return isValueSelectedItems ? value : [];
  }, [
    isValueSelectedItems,
    valuesExceedMax,
    isValueListExpanded,
    value,
    multiValueMaxDisplayCount,
  ]);

  // const [collapsedHeight, setCollapsedHeight] = useState(null);
  // const [ref, { height: maxHeight }] = useMeasure();
  const containerStyle = useSpring({
    height: "auto",
    config: {
      duration: 0,
    },
  });

  // const containerStyle = useSpring({
  //   height: isValueListExpanded ? maxHeight : collapsedHeight,
  //   config: {
  //     duration: value?.length > multiValueMaxDisplayCount + 1 ? 300 : 0,
  //   },
  // });

  const transitions = useTransition(visibleValues, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: {
      opacity: 0,
      delay: 0,
      config: {
        duration: 0,
      },
    },
    keys: (_, index) => index,
  });

  // const transitions = useTransition(visibleValues, {
  //   from: { opacity: isValueListExpanded ? 0 : 1 },
  //   enter: { opacity: 1 },
  //   leave: {
  //     opacity: 0,
  //     delay: value?.length < multiValueMaxDisplayCount + 1 ? 0 : 250,
  //     config: {
  //       duration: value?.length < multiValueMaxDisplayCount + 1 ? 0 : 100,
  //     },
  //   },
  //   keys: (_, index) => index,
  // });

  // useLayoutEffect(() => {
  //   // Initial setup
  //   if (!collapsedHeight && maxHeight > 0) {
  //     setCollapsedHeight(maxHeight);
  //   }

  //   // After setup, before list exceeds max
  //   if (
  //     collapsedHeight &&
  //     value?.length <= multiValueMaxDisplayCount + 1 &&
  //     collapsedHeight !== maxHeight
  //   ) {
  //     setCollapsedHeight(maxHeight);
  //   }

  //   // Select all
  //   if (
  //     collapsedHeight &&
  //     value?.length === options.length - 1 &&
  //     collapsedHeight !== maxHeight
  //   ) {
  //     setCollapsedHeight(maxHeight);
  //   }

  //   // Clear all
  //   if (
  //     collapsedHeight &&
  //     visibleValues.length === 0 &&
  //     collapsedHeight !== maxHeight
  //   ) {
  //     setCollapsedHeight(maxHeight);
  //   }
  // }, [
  //   maxHeight,
  //   collapsedHeight,
  //   multiValueMaxDisplayCount,
  //   value?.length,
  //   options.length,
  //   visibleValues.length,
  // ]);

  return (
    <StyledValueContainer {...rest} isMulti={isMulti}>
      {isMulti && (
        <animated.div style={containerStyle}>
          {/* <AnimatedMultiValueList ref={ref}> */}
          <AnimatedMultiValueList size={size}>
            {isValueSelectedItems ? (
              <>
                {transitions((style, item) => {
                  return (
                    <animated.div key={item.key} style={style}>
                      {item}
                    </animated.div>
                  );
                })}
                {valuesExceedMax && (
                  <SecondaryButton
                    onClick={isValueListExpanded ? collapseList : expandList}
                    onMouseDown={(event) => event.stopPropagation()}
                  >
                    {isValueListExpanded ? "See Less" : "See More"}
                  </SecondaryButton>
                )}
              </>
            ) : (
              value
            )}
            {input}
          </AnimatedMultiValueList>
        </animated.div>
      )}

      {!isMulti && children}
    </StyledValueContainer>
  );
};

const areOptionsGrouped = (options) => {
  return options.some((o) => !!o.children);
};

const getAllGroupedOptions = (options) => {
  return options.reduce((list, group) => {
    group.children.forEach((option) => list.push(option));
    return list;
  }, []);
};

const getAllGroupedOptionsByGroupId = (options, groupId) => {
  return options.find((group) => group.groupId === groupId).children;
};

const areAllOptionsSelected = (options, value) => {
  if (areOptionsGrouped(options)) {
    const groupedOptions = getAllGroupedOptions(options);

    return Array.isArray(value) && groupedOptions.length === value.length;
  } else {
    return Array.isArray(value) && options.length === value.length;
  }
};

const Combobox = ({
  id = "",
  name = "",
  label = "",
  placeholder = "",
  size = "large",
  options = [],
  isSearchable = false,
  isMultiSelect = false,
  isLoading = false,
  isDisabled = false,
  autoFocus = false,
  onChange = null,
  multiValueMaxDisplayCount = 10,
  errorMessage = "",
  defaultValue,
  value = "",
  dataCy,
}) => {
  const [isGrouped] = useState(areOptionsGrouped(options));
  const [isAllSelected, setIsAllSelected] = useState(
    areAllOptionsSelected(options, value)
  );

  const handleSelectChange = useCallback(
    (newValue) => {
      if (isMultiSelect) {
        const allOptionsSelected = areAllOptionsSelected(options, newValue);
        setIsAllSelected(allOptionsSelected);

        onChange(newValue.map((option) => option.value));
      } else {
        onChange(newValue.value);
      }
    },
    [isMultiSelect, onChange, options]
  );

  const toggleSelectAll = useCallback(() => {
    if (isAllSelected) {
      handleSelectChange([]);
    } else {
      if (isGrouped) {
        handleSelectChange(getAllGroupedOptions(options));
      } else {
        handleSelectChange(options);
      }
    }
  }, [isAllSelected, options, isGrouped, handleSelectChange]);

  const preparedOptions = useMemo(() => {
    const selectAllOption = {
      label: "Select All",
      value: "__ALL",
      onClick: toggleSelectAll,
    };
    const selectTypeOptions = isMultiSelect
      ? [selectAllOption].concat(options)
      : options;
    const flattenedOptions = selectTypeOptions.reduce((list, optionOrGroup) => {
      const children = optionOrGroup.children || [];
      return list.concat(optionOrGroup).concat(children);
    }, []);

    return flattenedOptions;
  }, [isMultiSelect, options, toggleSelectAll]);

  const preparedValue = useMemo(() => {
    if (Array.isArray(value)) {
      return preparedOptions.filter((option) => value.includes(option.value));
    } else {
      return preparedOptions.find((option) => option.value === value) || "";
    }
  }, [value, preparedOptions]);

  const toggleGroup = (groupId, isSelected) => {
    const childOptions = getAllGroupedOptionsByGroupId(options, groupId);

    if (isSelected) {
      const filteredValues = value
        .filter(
          (valueAsString) =>
            !childOptions.find((o) => o.value === valueAsString)
        )
        .map((v) => preparedValue.find((p) => p.value === v));

      handleSelectChange(filteredValues);
    } else {
      const missingValues = childOptions.filter((option) => {
        if (Array.isArray(preparedValue)) {
          // Return false if child option already exists in value array
          return !preparedValue.find((p) => p.value === option.value);
        } else {
          // When no values have been set yet
          return true;
        }
      });

      handleSelectChange(
        Array.isArray(preparedValue)
          ? preparedValue.concat(missingValues)
          : missingValues
      );
    }
  };

  if (isLoading) {
    return (
      <InputGroup>
        {label && <Label>{label}</Label>}
        <InputSkeletonContainer size={size} type="combobox">
          <Skeleton />
        </InputSkeletonContainer>
      </InputGroup>
    );
  }

  return (
    <InputGroup disabled={isDisabled} data-cy={dataCy}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <ReactSelect
        /*
         *  For testing
         */
        classNamePrefix="react-select"
        // menuIsOpen={true}
        /*
         *  Standard props
         */
        inputId={id}
        name={name}
        options={preparedOptions}
        // defaultValue={defaultValue}
        value={preparedValue}
        onChange={handleSelectChange}
        autoFocus={autoFocus}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isMulti={isMultiSelect}
        hideSelectedOptions={false}
        closeMenuOnSelect={!isMultiSelect}
        /*
         *  Custom props
         */
        size={size}
        errorMessage={errorMessage}
        multiValueMaxDisplayCount={multiValueMaxDisplayCount}
        isAllSelected={isAllSelected}
        isGrouped={isGrouped}
        onGroupClick={toggleGroup}
        /*
         *  Overrides
         */
        unstyled={true}
        // See this page for a list of replacable components:
        // https://react-select.com/components
        components={{
          Control: StyledControl,
          Placeholder: StyledPlaceholder,
          Input: CustomInput,
          SingleValue: StyledSingleValue,
          Menu: CustomStyledMenu,
          MenuList: CustomMenuList,
          IndicatorSeparator: StyledIndicatorSeparator,
          NoOptionsMessage: CustomNoOptionsMessage,
          IndicatorsContainer: CustomIndicatorsContainer,
          DropdownIndicator: CustomDropdownIndicator,
          ClearIndicator: CustomClearIndicator,
          ValueContainer: CustomValueContainer,
          MultiValue: CustomMultiValue,
          Option: CustomOption,
        }}
      />
      <FieldError isHidden={size === "small"}>{errorMessage}</FieldError>
    </InputGroup>
  );
};

Combobox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"]),
  errorMessage: PropTypes.string,
  isSearchable: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  multiValueMaxDisplayCount: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
      groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      children: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default Combobox;

const dh_colors = {
  grey_90: "#031621",
  grey_80: "#393D3F",
  grey_70: "#5F6467",
  grey_60: "#898D8F",
  grey_50: "#B4B8BA",
  grey_40: "#DADDE1",
  grey_30: "#E1E4E7",
  grey_20: "#EEEFF0",
  grey_10: "#F7F8F9",
  grey_5: "#F9F9FA",

  purple_90: "#2A0F4D",
  purple_80: "#300866",
  purple_70: "#3C0B77",
  purple_60: "#490F88",
  purple_50: "#541299",
  purple_40: "#6D36A5",
  purple_30: "#A989CA",
  purple_20: "#DDCDEF",
  purple_10: "#EDE6F5",
  purple_5: "#F5F2FC",

  blue_90: "#002630",
  blue_80: "#004C60",
  blue_70: "#007190",
  blue_60: "#0097C0",
  blue_50: "#00C1F3",
  blue_40: "#27D1FF",
  blue_30: "#5DDDFF",
  blue_20: "#93E8FF",
  blue_10: "#C9F4FF",

  green_90: "#081A10",
  green_80: "#103420",
  green_70: "#194E2F",
  green_60: "#21683F",
  green_50: "#29824F",
  green_40: "#39B66E",
  green_30: "#64CE92",
  green_20: "#98DEB6",
  green_10: "#CBEFDB",

  cream_90: "#382920",
  cream_80: "#70533F",
  cream_70: "#A67D61",
  cream_60: "#C5AB99",
  cream_50: "#E9DED4",
  cream_40: "#EAE1DA",
  cream_30: "#EFE8E3",
  cream_20: "#F5F0ED",
  cream_10: "#FAF7F6",

  salmon_90: "#3E1209",
  salmon_80: "#7C2313",
  salmon_70: "#BA351C",
  salmon_60: "#E2563C",
  salmon_50: "#EB8C7A",
  salmon_40: "#EFA395",
  salmon_30: "#F3BAAF",
  salmon_20: "#F7D1CA",
  salmon_10: "#FBE8E4",

  orange_90: "#2A1207",
  orange_80: "#54240D",
  orange_70: "#7D3613",
  orange_60: "#A7481A",
  orange_50: "#D15921",
  orange_40: "#E27746",
  orange_30: "#E99974",
  orange_20: "#F0BBA2",
  orange_10: "#F8DDD1",

  gold_90: "#2B1B04",
  gold_80: "#563607",
  gold_70: "#80510B",
  gold_60: "#AB6C0E",
  gold_50: "#AB6C0E",
  gold_40: "#EEA232",
  gold_30: "#F2B965",
  gold_20: "#F6D198",
  gold_10: "#FBE8CC",

  yellow_90: "#332B01",
  yellow_80: "#332B01",
  yellow_70: "#988102",
  yellow_60: "#CAAB03",
  yellow_50: "#FBD506",
  yellow_40: "#FCDC30",
  yellow_30: "#FDE66A",
  yellow_20: "#FDEE9B",
  yellow_10: "#FEF7CD",

  status_success_50: "#29824F",
  status_success_20: "#DFF5E8",

  status_warning_50: "#D68712",
  status_warning_20: "#FCF0DF",

  status_error_50: "#BA351C",
  status_error_20: "#FBE8E4",

  status_info_50: "#0097C0",
  status_info_20: "#E4FAFF",

  white: "#ffffff",
  black: "#000000",
};

// This is used in storybook, so I am still keeping it as default export.
// Old name was theme.
const common = {
  color: {
    heading: "#000000",
    body: "#000000",
    icons: "#9F9F9F",
    white: "#ffffff",
    black: "#000000",
    primary: "#712A6F",
    red: "#ff2a00",
    "text-blue": "#2B1850",
  },
  gap: {
    xxsmall: "5px",
    xsmall: "10px",
    small: "15px",
    medium: "20px",
    large: "30px",
  },
  padding: {
    small: "15px",
    medium: "20px",
    large: "30px",
  },
  margin: {
    0: "0px",
    h1: "15px",
    h2: "15px",
    h3: "10px",
    h4: "14px",
    h5: "14px",
    h6: "4px",
    p: "20px",
    sub: "10px",
    xs: "10px",
    sm: "15px",
    md: "20px",
    lg: "25px",
    xl: "30px",
  },
  borderRadius: {
    none: "0px",
    small: "4px",
    medium: "5px",
    full: "50%",
  },
  dh_colors,
};

const lightTheme = {
  ...common,
  app: {
    backgroundColor: dh_colors.purple_5,
  },
  components: {
    button: {
      contained: {
        backgroundColor: dh_colors.purple_50,
        textColor: dh_colors.white,
        hoverBackgroundColor: dh_colors.purple_40,
      },
      outlined: {
        borderColor: dh_colors.purple_50,
        backgroundColor: dh_colors.white,
        hoverBackgroundColor: dh_colors.purple_20,
        textColor: dh_colors.purple_50,
      },
      text: {
        backgroundColor: dh_colors.white,
        textColor: dh_colors.purple_50,
      },
    },
  },
};

const darkTheme = {
  ...common,
  app: {
    backgroundColor: dh_colors.purple_20,
    // This is not as per design, I have added just to check the theme change logic.
    // There is no button option as of now, just change default value in themeProvider to dark
  },
};

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

export const getColor = (theme, key) => {
  const val = deepGetByPath(theme, key);
  if (val) return val;
  return "#000";
};

const deepGetByPath = (obj, path) => {
  if (!obj || !path) {
    return undefined;
  }
  if (typeof path !== "string") {
    return undefined;
  }

  //This will return the value of the field nested deep within the component for the selected theme (default theme is light)
  return path.split(".").reduce((xs, x) => xs?.[x] ?? undefined, obj);
};
// Example
// const Obj = { a : { b : 2 } }
// deepGetByPath(Obj,'a.b') return 2

export const themeModes = {
  light: "light",
  dark: "dark",
};

export { themes, dh_colors };
export default common;

import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { Row, TinyIcon } from "core/styles";

const LabelWithTooltipContainer = styled(Row)`
  gap: 5px;
  align-items: center;
  min-width: 40px;
  svg {
    color: #000;
  }
`;

const LabelWithTooltip = ({ info, children }) => {
  return (
    <LabelWithTooltipContainer>
      {children} <TinyIcon icon={faCircleInfo} id={children} />
      <Tooltip
        anchorId={children}
        html={typeof info === "string" ? info : null}
        content={typeof info !== "string" ? info : null}
        positionStrategy="fixed"
        style={{ zIndex: "1000" }}
      />
    </LabelWithTooltipContainer>
  );
};

export default LabelWithTooltip;

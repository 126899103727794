import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { faHospitals, faUserDoctor } from "@fortawesome/pro-regular-svg-icons";

import Table, {
  SearchableTableCell,
  ServerSidePaginator,
} from "core/components/Table";
import { IconTableCell } from "core/components/Table/Cell";
import Search from "core/components/Search";
import { useMemo } from "react";
import Popover from "core/components/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/pro-light-svg-icons";

const tableSearchStyles = {
  container: {
    width: "100%",
    height: "auto",
  },
  input: {
    height: "24px",
  },
  cancelButton: {
    marginRight: "-3px",
  },
  icon: {
    right: "8px",
    top: "7px",
    width: "10px",
    height: "10px",
  },
};

const baseTableSearchProps = {
  placeholder: " ",
  styles: tableSearchStyles,
};

const columnHelper = createColumnHelper();

const AliasesTable = (props) => {
  const {
    data,
    isRefreshing,
    isLoading,
    selectedRowIds,
    toggleSelectAllRows,
    toggleSelectSingleRow,
    pagination,
    changePage,
    sorting,
    handleSortChange,
    filter,
    updateFilter,
    queryKey,
    canUpdate,
  } = props;

  const navigate = useNavigate();
  const { orgId: organizationId } = useParams();

  const filteredObject = (obj, predicate) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => predicate(value))
    );
  };

  const columns = useMemo(() => {
    return [
      columnHelper.group({
        id: "checkbox",
        size: 40,
        customStyles: {
          minWidth: "40px",
        },
        header: (props) => {
          return (
            <input
              type="checkbox"
              onChange={toggleSelectAllRows}
              checked={
                selectedRowIds.length && data.length === selectedRowIds.length
              }
            />
          );
        },
        columns: [
          columnHelper.display({
            id: "checkbox",
            size: 40,
            customStyles: {
              minWidth: "40px",
              textOverflow: "inherit",
            },
            header: "",
            cell: (props) => {
              return (
                <input
                  type="checkbox"
                  onChange={() =>
                    toggleSelectSingleRow(props.row.original.provider_npi)
                  }
                  checked={selectedRowIds.includes(
                    props.row.original.provider_npi
                  )}
                />
              );
            },
          }),
        ],
      }),
      columnHelper.group({
        header: "Type",
        size: 50,
        customStyles: {
          minWidth: "50px",
        },
        columns: [
          columnHelper.accessor("npi_type", {
            id: "npi_type",
            size: 50,
            customStyles: {
              minWidth: "50px",
            },
            enableResizing: false,
            enableSorting: false,
            header: "",
            cell: (props) => {
              const isPractice = props.getValue() === 2;
              return (
                <IconTableCell
                  icon={isPractice ? faHospitals : faUserDoctor}
                  iconAlt={isPractice ? "Practice" : "Provider"}
                  primaryColor="#712A6F"
                />
              );
            },
          }),
        ],
      }),
      columnHelper.accessor("provider_npi", {
        id: "provider_npi",
        header: "NPI",
        size: 150,
        customStyles: {
          minWidth: "125px",
        },
        enableSorting: filter.name !== "provider_npi",
        columns: [
          columnHelper.display({
            id: "search_provider_npi",
            accessorKey: "provider_npi",
            size: 150,
            customStyles: {
              minWidth: "125px",
            },
            header: (props) => {
              return (
                <Search
                  name="provider_npi"
                  value={filter.name === "provider_npi" ? filter.value : ""}
                  autoFocus={filter.name === "provider_npi"}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    const trimmedValue = value.trim();

                    if (!trimmedValue) {
                      updateFilter({ name: null, value: "" });
                    } else {
                      updateFilter({ name, value });
                    }
                  }}
                  {...baseTableSearchProps}
                />
              );
            },
            cell: SearchableTableCell,
            enableSorting: false,
          }),
        ],
      }),
      columnHelper.group({
        header: "Name",
        size: 250,
        customStyles: {
          minWidth: "225px",
        },
        columns: [
          columnHelper.accessor("name", {
            id: "name",
            header: "",
            size: 250,
            customStyles: {
              minWidth: "225px",
            },
            cell: SearchableTableCell,
            enableSorting: false,
          }),
        ],
      }),
      columnHelper.accessor("provider_name", {
        id: "provider_name",
        header: "Alias",
        enableSorting: filter.name !== "provider_name",
        size: 250,
        customStyles: {
          minWidth: "225px",
        },
        columns: [
          columnHelper.display({
            id: "search_provider_name",
            accessorKey: "provider_name",
            size: 250,
            customStyles: {
              minWidth: "225px",
            },
            header: () => {
              return (
                <Search
                  name="provider_name"
                  value={filter.name === "provider_name" ? filter.value : ""}
                  autoFocus={filter.name === "provider_name"}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    const trimmedValue = value.trim();

                    if (!trimmedValue) {
                      updateFilter({ name: null, value: "" });
                    } else {
                      updateFilter({ name, value });
                    }
                  }}
                  {...baseTableSearchProps}
                />
              );
            },
            cell: SearchableTableCell,
            enableSorting: false,
          }),
        ],
      }),
      columnHelper.accessor("practice_name", {
        id: "practice_name",
        header: "Practice Name",
        enableSorting: filter.name !== "practice_name",
        size: 250,
        customStyles: {
          minWidth: "225px",
        },
        columns: [
          columnHelper.display({
            id: "search_practice_name",
            accessorKey: "practice_name",
            size: 250,
            customStyles: {
              minWidth: "225px",
            },
            header: () => {
              return (
                <Search
                  name="practice_name"
                  value={filter.name === "practice_name" ? filter.value : ""}
                  autoFocus={filter.name === "practice_name"}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    const trimmedValue = value.trim();

                    if (!trimmedValue) {
                      updateFilter({ name: null, value: "" });
                    } else {
                      updateFilter({ name, value });
                    }
                  }}
                  {...baseTableSearchProps}
                />
              );
            },
            cell: SearchableTableCell,
            enableSorting: false,
          }),
        ],
      }),
      columnHelper.accessor("healthcare_organization_name", {
        id: "healthcare_organization_name",
        header: "Organization Name",
        enableSorting: filter.name !== "healthcare_organization_name",
        columns: [
          columnHelper.display({
            id: "search_healthcare_organization_name",
            accessorKey: "healthcare_organization_name",
            size: 250,
            customStyles: {
              minWidth: "225px",
            },
            header: () => {
              return (
                <Search
                  name="healthcare_organization_name"
                  value={
                    filter.name === "healthcare_organization_name"
                      ? filter.value
                      : ""
                  }
                  autoFocus={filter.name === "healthcare_organization_name"}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    const trimmedValue = value.trim();

                    if (!trimmedValue) {
                      updateFilter({ name: null, value: "" });
                    } else {
                      updateFilter({ name, value });
                    }
                  }}
                  {...baseTableSearchProps}
                />
              );
            },
            cell: SearchableTableCell,
            enableSorting: false,
          }),
        ],
      }),
      columnHelper.display({
        id: "actions",
        size: 65,
        customStyles: {
          minWidth: "65px",
          justifyContent: "flex-end",
        },
        cell: ({ row }) => {
          const openRowMenuId = row.original.provider_npi;

          return (
            <Popover
              placement="bottom-end"
              offset={3}
              actions={[
                {
                  name: "Edit",
                  onClick: () => {
                    // Matt: Keep url as succinct as possible
                    const {
                      provider_npi: npi,
                      npi_type,
                      name,
                      ...params
                    } = data.find((r) => r.provider_npi === openRowMenuId);

                    const filteredParams = filteredObject(
                      params,
                      (item) => item !== null
                    );

                    const updateUrl = `/config/${organizationId}/managed-providers/aliases/update/${openRowMenuId}`;

                    navigate(
                      `${updateUrl}?${createSearchParams(filteredParams)}`,
                      {
                        state: { queryKey },
                      }
                    );
                  },
                },
                {
                  name: "Remove",
                  onClick: () => {
                    const removeUrl = `/config/${organizationId}/managed-providers/aliases/remove/${openRowMenuId}`;

                    navigate(removeUrl, {
                      state: { queryKey },
                    });
                  },
                },
              ]}
              TriggerComponent={(props) => {
                const { setRef, email, name, isNavOpen, ...triggerProps } =
                  props;

                return (
                  <div
                    style={{
                      width: "50px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faEllipsis}
                      style={{
                        width: "100%",
                        fontSize: "19px",
                        cursor: "pointer",
                      }}
                      ref={setRef}
                      {...triggerProps}
                    />
                  </div>
                );
              }}
            />
          );
        },
      }),
    ];
  }, [
    data,
    queryKey,
    organizationId,
    navigate,
    filter,
    selectedRowIds,
    toggleSelectAllRows,
    toggleSelectSingleRow,
    updateFilter,
  ]);

  const columnVisibility = useMemo(
    () => ({
      checkbox: canUpdate,
      actions: canUpdate,
    }),
    [canUpdate]
  );

  return (
    <>
      <Table
        data={data}
        columns={columns}
        isRefreshing={isRefreshing}
        isLoading={isLoading}
        defaultState={{ sorting }}
        sorting={sorting}
        getRowId={(row) => {
          return row.provider_npi;
        }}
        selectedRowIds={selectedRowIds}
        serverSideSorting={true}
        multiSortEnabled={false}
        handleSortChange={handleSortChange}
        serverSidePagination={true}
        columnVisibility={columnVisibility}
        PaginationComponent={
          <ServerSidePaginator
            prevPageToken={pagination.page_previous}
            nextPageToken={pagination.page_next}
            handlePreviousClick={() => changePage("PREVIOUS")}
            handleNextClick={() => changePage("NEXT")}
            isRefreshing={isRefreshing}
          />
        }
      />
    </>
  );
};

export default AliasesTable;

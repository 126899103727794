export const ROLES = {
  systemAdmin: "Populi Admin",
  systemViewer: "Populi Viewer",
  orgAdmin: "Org Admin",
  orgUser: "Org User",
};

export const REVERSE_LOOKUP_ROLES = {
  [ROLES.systemAdmin]: "systemAdmin",
  [ROLES.systemViewer]: "systemViewer",
  [ROLES.orgAdmin]: "orgAdmin",
  [ROLES.orgUser]: "orgUser",
};

export const TABLEAU_ROLES = {
  viewer: "Viewer",
  explorer: "Explorer",
  creator: "Creator",
};

export const REVERSE_LOOKUP_TABLEAU_ROLES = {
  [TABLEAU_ROLES.viewer]: "viewer",
  [TABLEAU_ROLES.explorer]: "explorer",
  [TABLEAU_ROLES.creator]: "creator",
};

export const API_ERRORS = {
  PASSWORD_EXPIRED: "auth/password-expired",
  PASSWORD_EXPIRED_WITHIN_GRACE_PERIOD:
    "auth/password-expired-within-grace-period",
};

export const DEFAULT_ORG = {
  active: false,
  name: "",
  id: "",
  domain: [],
  callbackUrls: [],
  primaryContact: { name: "", email: "" },
  licenses: {
    tableau_user_licenses: {
      explorer_count: 0,
      viewer_count: 0,
      // Can't add creator_count here because api throws error when org !== populi
    },
    user_limit: 20,
  },
  preferences: {
    bi_portal: {
      default_states: [],
    },
  },
  customer_segment: "",
  medicare_licensed: false,
};

export const IMMUTABLE_LICENSES_PROPERTIES = ["deployment", "base_attributes"];

export const DIRECT_DATA_ACCESS_STATUS_LABEL = {
  ENABLED: "Enabled",
  DISABLED: "Disabled",
};

export const RELEASE_STAGES = {
  ALPHA: "alpha", // not used yet
  BETA: "beta",
  GA: "general-availability",
};

export const FLAG_KEYS = {
  DATA_ACCESS_MANAGEMENT: "feature:data-access-management",
};

export const FLAG_ENTITIES = {
  ORGANIZATION: "organization",
  USER: "user", // not used yet
};

export const PERMISSIONS = {
  [ROLES.systemAdmin]: [
    "admin:read",
    "org-management:read",
    "org-management:write",
    "org-management:switch",
    "user-management:read",
    "user-management:write",
    "configurations:read",
    "configurations:write",
    "permission:catalog:read",
    "permission:catalog-version:read",
    "permission:user:reset-password",
  ],
  [ROLES.systemViewer]: [
    "admin:read",
    "org-management:read",
    "org-management:switch",
    "org-management:write",
    "user-management:read",
    "configurations:read",
    "user-management:write",
    "permission:catalog:read",
    "permission:catalog-version:read",
    "permission:user:reset-password",
    {
      name: "user-management:write",
      rule: ({ userEmail, paramEmail }) => userEmail === paramEmail,
    },
  ],
  [ROLES.orgAdmin]: [
    "admin:read",
    "user-management:read",
    "user-management:write",
    "configurations:read",
    "configurations:write",
    "org-management:write",
    "permission:user:reset-password",
  ],
  [ROLES.orgUser]: [
    "configurations:read",
    {
      name: "user-management:write",
      rule: ({ userEmail, paramEmail }) => userEmail === paramEmail,
    },
  ],
};

import { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import {
  faHouseChimney,
  faPeopleRoof,
  faCircleQuestion,
  faUser,
  faCode,
  faChevronRight,
  faChartColumn,
  faBuildingUser,
  faCity,
} from "@fortawesome/pro-regular-svg-icons";

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import { getOrganizationById, getUsers } from "modules/admin/actions";

import { ROLES, REVERSE_LOOKUP_TABLEAU_ROLES } from "core/constants";

import { Tooltip } from "react-tooltip";
import { useQuery } from "@tanstack/react-query";
import Popover from "./Popover";
import Typography from "./Typography/Typography";
import useNavigation from "core/hooks/useNavigation";
import useCurrentUser from "../hooks/useCurrentUser";

const collapsedWidth = "64px";
const expandedWidth = "264px";

const NavHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 6px;
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;

  img {
    display: block;
    height: 31px;
  }

  & > div {
    margin-left: 6px;
    color: #fff;
    font-family: Inter;
    font-weight: 600;
    opacity: 1;
    transition: opacity 500ms linear;

    div:first-child {
      font-size: 19px;
      letter-spacing: 0.5px;
    }

    div:last-child {
      font-size: 16px;
      letter-spacing: 0.3px;
    }
  }

  &[data-nav-open="true"] {
  }

  &[data-nav-open="false"] {
    & > div {
      opacity: 0;
    }
  }
`;

const NavLink = styled(Link)`
  overflow: hidden;
  contain: strict;
  user-select: none;
  position: relative;
  text-decoration: none;

  & > div {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    color: #ffffff;
    font-size: 16px;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    white-space: nowrap;

    :hover {
      background-color: #543f70;
    }
  }

  &:hover {
    text-decoration: none;
  }

  ${(props) =>
    props.$isActive &&
    `
    font-weight: 600;

    &:hover {
      background-color: #543f70;
    }
  `}

  &[data-nav-open="true"] {
  }

  &[data-nav-open="false"] {
  }
`;

const NavLinkIcon = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &[data-nav-open="true"] {
  }

  &[data-nav-open="false"] {
  }
`;

const NavLinkTitle = styled.div`
  margin-left: 15px;
  transition: opacity 500ms linear;

  &[data-nav-open="true"] {
    opacity: 1;
  }

  &[data-nav-open="false"] {
    opacity: 0;
  }
`;

const DrawerAside = styled.aside`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #2a0f4d;
  width: ${collapsedWidth};
  height: 100%;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  transition: width 500ms ease-in-out;

  ${(props) =>
    props.$isOpen &&
    `
    width: ${expandedWidth};
    // align-items: center;
  `}
`;

const ExternalLink = styled(NavLink)``;

const MainNav = styled.nav`
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const NavToggle = styled.div`
  position: absolute;
  top: 60px;
  right: -13px;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  background-color: #785c96;
  border-radius: 9999px;
  color: #ffffff;
  cursor: pointer;
  transition: transform 500ms ease-in-out;

  ${(props) =>
    props.$isNavOpen &&
    `
      transform: rotate(180deg);
  `}
`;

const ProfileMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  padding: 6px;
  height: 48px;

  width: 100%;
  white-space: nowrap;
  user-select: none;
  transition: opacity 500ms linear;

  &:hover {
    background-color: #543f70;
  }
`;

const useOrganizationByIdQuery = ({ id }) => {
  const fetchQueryCall = useAuthenticatedCall((req) =>
    getOrganizationById({ ...req, organizationId: id })
  );

  const { data } = useQuery({
    queryKey: ["organizations", id],
    queryFn: fetchQueryCall,
  });

  return data;
};

const useUserByEmailQuery = ({ id, email }) => {
  const fetchQueryCall = useAuthenticatedCall((req) =>
    getUsers({ ...req, organizationId: id, email })
  );

  const { data } = useQuery({
    queryKey: ["user", id],
    queryFn: fetchQueryCall,
  });

  return data?.[0];
};

const ProfileTrigger = (props) => {
  const { setRef, isNavOpen, name, email, role, ...triggerProps } = props;

  return (
    <>
      <ProfileMenu
        id="profileMenu"
        $isNavOpen={isNavOpen}
        data-cy="profileMenuButton"
        ref={setRef}
        {...triggerProps}
      >
        <NavLinkIcon data-nav-open={isNavOpen} style={{ marginLeft: "3px" }}>
          <div
            style={{
              backgroundColor: "#00C1F3",
              borderRadius: "3px",
              padding: "4px",
            }}
          >
            <FontAwesomeIcon
              icon={faUser}
              size="lg"
              style={{
                color: "#fff",
              }}
            />
          </div>
        </NavLinkIcon>

        <NavLinkTitle data-nav-open={isNavOpen}>
          <Typography variant="h6" color="white" noMargin>
            {name || email}
          </Typography>

          <Typography variant="sub-text" color="white" noMargin>
            {role}
          </Typography>
        </NavLinkTitle>
      </ProfileMenu>

      <Tooltip
        anchorSelect="#profileMenu"
        children="Settings"
        place="right"
        positionStrategy="absolute"
        offset={20}
        style={{ zIndex: "10000", backgroundColor: "#2A0F4D" }}
        hidden={isNavOpen || triggerProps["aria-expanded"] === "true"}
      />
    </>
  );
};

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { isNavOpen, setNavOpen } = useNavigation();
  const { currentUser: user } = useCurrentUser();
  const { role, email, organizationId } = user;

  const userOrganization = useOrganizationByIdQuery({ id: organizationId });
  const userData = useUserByEmailQuery({ id: organizationId, email });

  const hasBiPortalLicense = useMemo(
    () =>
      userData?.tableauConfig?.tableauLicense &&
      Object.values(REVERSE_LOOKUP_TABLEAU_ROLES).includes(
        userData?.tableauConfig?.tableauLicense
      ),
    [userData]
  );
  const biPortalUrl = useMemo(
    () => userOrganization?.onboarding?.bi?.portal_url,
    [userOrganization]
  );
  const biPortal = useMemo(
    () =>
      userData && userOrganization // user and org has loaded
        ? hasBiPortalLicense && biPortalUrl
          ? {
              // valid bi portal link and user license
              disabled: false,
              errorMessage: null,
            }
          : {
              // invalid
              disabled: true,
              errorMessage: !hasBiPortalLicense
                ? "You do not have a BI Portal license assigned." // no BI licence for user
                : "The BI Portal is not set up for your userOrganization.", // no BI portal link for org
            }
        : {
            // user and/or org not loaded yet, disable button
            disabled: true,
            errorMessage: null,
          },
    [userData, userOrganization, hasBiPortalLicense, biPortalUrl]
  );

  const routes = [
    {
      to: "/",
      name: "Home",
      icon: faHouseChimney,
    },
    {
      to: `/config/${user.organizationId}/managed-providers`,
      name: "Managed Providers",
      permissions: "configurations:read",
      icon: faPeopleRoof,
    },
    {
      to: "/admin/org-management",
      name: "Organizations",
      permissions: "org-management:read",
      icon: faCity,
      exact: true,
    },
    {
      to: `/admin/org-management/${user.organizationId}/update`,
      name: "My Organization",
      permissions: "user-management:read",
      icon: faBuildingUser,
    },
  ];

  const {
    setUserSearchValue,
    setOrgSearchValue,
    setCurrentUserPageNumber,
    setCurrentOrgPageNumber,
  } = useNavigation();

  const onRouteChange = () => {
    setOrgSearchValue("");
    setUserSearchValue("");
    setCurrentUserPageNumber(0);
    setCurrentOrgPageNumber(0);
  };

  return (
    <DrawerAside $isOpen={isNavOpen}>
      <NavToggle onClick={() => setNavOpen(!isNavOpen)} $isNavOpen={isNavOpen}>
        <FontAwesomeIcon
          icon={faChevronRight}
          style={{ color: "#ffffff", width: "15px" }}
        />
      </NavToggle>
      <NavHeader data-nav-open={isNavOpen}>
        <img src="/dh-logo-mark.svg" alt="logo" height="31px" />
        <div>
          <div>DEFINITIVE</div>
          <div>HEALTHCARE</div>
        </div>
      </NavHeader>

      <MainNav>
        <div>
          <AnimatePresence mode="wait">
            {routes
              .filter(
                (route) =>
                  !route.permissions ||
                  user.permissions.includes(route.permissions)
              )
              .map(({ to, name, icon, exact }) => {
                const isActive =
                  to === "/"
                    ? location.pathname === to
                    : exact
                    ? location.pathname === to
                    : location.pathname.includes(to);

                const id = name.split(" ").join("");

                return (
                  <span key={id}>
                    <NavLink
                      id={id}
                      to={to}
                      data-nav-open={isNavOpen}
                      $isActive={isActive}
                      onClick={onRouteChange}
                      state={{ from: id }}
                      data-tooltip-id={isNavOpen ? null : id}
                      data-tooltip-content={name}
                    >
                      {isActive && (
                        <motion.div
                          layoutId="activeBackgroundMain"
                          style={{
                            position: "absolute",
                            zIndex: 1,
                            height: "40px",
                            // width: "100%",
                            backgroundColor: "#543f70",
                            borderRadius: "5px",
                          }}
                        />
                      )}
                      <div style={{ position: "relative", zIndex: 2 }}>
                        <NavLinkIcon data-nav-open={isNavOpen}>
                          <FontAwesomeIcon icon={icon} size="lg" />
                        </NavLinkIcon>

                        <NavLinkTitle data-nav-open={isNavOpen}>
                          {name}
                        </NavLinkTitle>
                      </div>
                    </NavLink>

                    <Tooltip
                      id={id}
                      place="right"
                      positionStrategy="absolute"
                      offset={20}
                      style={{ zIndex: "10000", backgroundColor: "#2A0F4D" }}
                      openEvents={{
                        focus: false, // prevents tooltip from showing if trigger is last focused element (side nav)s
                        mouseover: true,
                      }}
                      role="tooltip"
                    />
                  </span>
                );
              })}
          </AnimatePresence>
        </div>

        {/* Bottom Section */}
        <div>
          {!biPortal.disabled && (
            <ExternalLink
              id="biPortal"
              as="a"
              href={biPortalUrl}
              target="_blank"
              rel="noreferrer"
            >
              <div style={{ position: "relative", zIndex: 2 }}>
                <NavLinkIcon data-nav-open={isNavOpen}>
                  <FontAwesomeIcon
                    icon={faChartColumn}
                    size="lg"
                    style={{ width: "25px" }}
                  />
                </NavLinkIcon>
                <NavLinkTitle data-nav-open={isNavOpen}>BI Portal</NavLinkTitle>
              </div>
            </ExternalLink>
          )}
          <Tooltip
            anchorSelect="#biPortal"
            children="BI Portal"
            place="right"
            positionStrategy="absolute"
            offset={20}
            style={{ zIndex: "10000", backgroundColor: "#2A0F4D" }}
          />

          <ExternalLink
            id="apiDocs"
            as="a"
            href="https://api.populi.ai"
            target="_blank"
            rel="noreferrer"
          >
            <div style={{ position: "relative", zIndex: 2 }}>
              <NavLinkIcon data-nav-open={isNavOpen}>
                <FontAwesomeIcon icon={faCode} size="lg" />
              </NavLinkIcon>
              <NavLinkTitle data-nav-open={isNavOpen}>API Docs</NavLinkTitle>
            </div>
          </ExternalLink>

          <Tooltip
            anchorSelect="#apiDocs"
            children="API Docs"
            place="right"
            positionStrategy="absolute"
            offset={20}
            style={{ zIndex: "10000", backgroundColor: "#2A0F4D" }}
          />

          <ExternalLink
            id="supportPortal"
            as="a"
            href="https://success.populi.ai/hc/en-us"
            target="_blank"
            rel="noreferrer"
          >
            <div style={{ position: "relative", zIndex: 2 }}>
              <NavLinkIcon data-nav-open={isNavOpen}>
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  size="lg"
                  style={{ width: "25px" }}
                />
              </NavLinkIcon>
              <NavLinkTitle data-nav-open={isNavOpen}>
                Support Portal
              </NavLinkTitle>
            </div>
          </ExternalLink>

          <Tooltip
            anchorSelect="#supportPortal"
            children="Support Portal"
            place="right"
            positionStrategy="absolute"
            offset={20}
            style={{ zIndex: "10000", backgroundColor: "#2A0F4D" }}
          />

          <Popover
            placement="right-end"
            offset={20}
            navProfilePopover={true}
            userInfo={user}
            isNavOpen={isNavOpen}
            TriggerComponent={ProfileTrigger}
            actions={[
              {
                name: "View Profile",
                onClick: (event, setPopoverOpen) => {
                  navigate(
                    `/admin/org-management/${user.organizationId}/users/${email}/profile/general`,
                    {
                      state: {
                        from:
                          location?.state?.from || window.history.state.from,
                        orgId: location?.state?.orgId,
                        userEmail: location?.state?.userEmail,
                      },
                    }
                  );
                  setPopoverOpen(false);
                },
              },
              ...([ROLES.systemAdmin, ROLES.systemViewer].includes(role)
                ? [
                    {
                      name: [ROLES.systemViewer].includes(role)
                        ? "View Organizations"
                        : "Manage Organizations",
                      onClick: (event, setPopoverOpen) => {
                        navigate("/admin/org-management");
                        setPopoverOpen(false);
                      },
                    },
                  ]
                : []),
              ...([ROLES.orgAdmin].includes(role)
                ? [
                    {
                      name: "My Organization",
                      onClick: (event, setPopoverOpen) => {
                        navigate(
                          `/admin/org-management/${user.organizationId}/update`
                        );
                        setPopoverOpen(false);
                      },
                    },
                  ]
                : []),
              {
                name: "Logout",
                dataCy: "logoutMenuLink",
                onClick: () => {
                  auth.removeUser();
                  auth.signoutRedirect();
                },
              },
            ]}
          />
        </div>
      </MainNav>
    </DrawerAside>
  );
};

export default Navigation;

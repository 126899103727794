import { useState } from "react";
import Typography from "core/components/Typography";
import { ThemeProvider } from "styled-components";
import theme from "theme";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBracketsCurly } from "@fortawesome/pro-solid-svg-icons";
import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import { getCustomerSegments } from "./actions";
import Modal from "core/components/Modal";
import ReactJsonView from "@microlink/react-json-view";
import { ViewJsonButton, JSONContainer } from "./styles";

const CustomerSegmentTooltip = ({ listStyle }) => {
  const fetchCustomerSegments = useAuthenticatedCall(getCustomerSegments);
  const { data: customerSegments } = useQuery({
    queryKey: ["customer-segments", null, {}],
    queryFn: fetchCustomerSegments,
    placeholderData: {},
  });

  let segmentNames = [];
  const customerSegmentKeys = Object.keys(customerSegments);
  for (let i = 0; i < customerSegmentKeys.length; i++) {
    const option = Object.values(customerSegments).find(
      (item) => item.id === customerSegmentKeys[i]
    )?.name;
    segmentNames.push(option);
  }

  const [isModalOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [jsonRootName, setJsonRootName] = useState(false);

  const openModalWithData = () => {
    setJsonRootName("customerSegments");
    setModalContent(customerSegments);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setJsonRootName(false);
    setModalContent({});
    setModalIsOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          textAlign: "left",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "relative",
            whiteSpace: "break-spaces",
            fontSize: "0.75rem",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4" color="white">
              Segments
            </Typography>
            <ViewJsonButton
              onClick={(event) => {
                event.stopPropagation();
                openModalWithData();
              }}
            >
              <FontAwesomeIcon
                icon={faBracketsCurly}
                style={{
                  color: "#fff",
                  border: "1px solid #fff",
                  borderRadius: "100%",
                  padding: "4px",
                }}
              />
            </ViewJsonButton>
          </div>
          <ul style={{ listStyleType: "disc", paddingLeft: 15 }}>
            {segmentNames.map((segment) => (
              <li key={segment.id} style={{ ...listStyle }}>
                {segment}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Modal
        visible={isModalOpen}
        dismiss={closeModal}
        title="JSON Viewer"
        style={{
          width: "60vw",
          marginTop: "5vh",
        }}
      >
        <JSONContainer>
          <ReactJsonView
            name={jsonRootName}
            src={modalContent}
            collapsed={4}
            displayObjectSize={false}
            displayDataTypes={false}
            quotesOnKeys={false}
            displayArrayKey={false}
          />
        </JSONContainer>
      </Modal>
    </ThemeProvider>
  );
};

export default CustomerSegmentTooltip;

import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import Skeleton from "react-loading-skeleton";
import { DateTime } from "luxon";
import {
  faBuilding,
  faBuildingUser,
  faCheckSquare,
} from "@fortawesome/pro-regular-svg-icons";
import Popover from "core/components/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/pro-light-svg-icons";

import Table, {
  HeaderWithTooltip,
  SearchableTableCell,
  TextWithIconTableCell,
} from "core/components/Table";
import Pill from "core/components/Pill";
import Link from "core/components/Link";
import DeactivateOrganizationModal from "./DeactivateOrgModal";
import ActivateOrganizationModal from "./ActivateOrgModal";
import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import {
  getOrganizationDeployment,
  getOrganizationOnboarding,
  getCustomerSegments,
} from "./actions";
import Typography from "core/components/Typography";
import { ThemeProvider } from "styled-components";
import theme from "theme";
import ReactJsonView from "@microlink/react-json-view";
import { faBracketsCurly } from "@fortawesome/pro-solid-svg-icons";
import Modal from "core/components/Modal";
import { Row } from "core/styles";
import CustomerSegmentTooltip from "./CustomerSegmentTooltip";
import { ViewJsonButton, JSONContainer } from "./styles";

// import useNavigation from "core/hooks/useNavigation";

const dateTimeFormat = "FF";
const durationFormat = "m'm ' s's'";

const ONBOARDING_LABELS = {
  "not onboarded": "Not Setup",
  timeout: "Timeout",
  "in progress": "In Progress",
  succeeded: "Active",
  failed: "Error",
  inactive: "Inactive",
  queued: "Queued",
  unknown: "Unknown",
};

const ONBOARDING_VARIANTS = {
  "not onboarded": "default",
  timeout: "failed",
  "in progress": "info",
  succeeded: "success",
  failed: "failed",
  queued: "pending",
  unknown: "warning",
  inactive: "default",
};

const DEPLOYMENT_STATUSES = {
  DEFAULT: "Not Configured",
  SUCCESS: "Deployed",
  IN_PROGRESS: "In Progress",
  FAILED: "Failed",
  TIMEOUT: "Timeout",
  QUEUED: "Queued",
  UNKNOWN: "Unknown",
};

const DEPLOYMENT_STATUS_MAP = {
  "not deployed": DEPLOYMENT_STATUSES.DEFAULT,
  "in progress": DEPLOYMENT_STATUSES.IN_PROGRESS,
  queued: DEPLOYMENT_STATUSES.QUEUED,
  succeeded: DEPLOYMENT_STATUSES.SUCCESS,
  failed: DEPLOYMENT_STATUSES.FAILED,
  timeout: DEPLOYMENT_STATUSES.TIMEOUT,
  unknown: DEPLOYMENT_STATUSES.UNKNOWN,
};

const DEPLOYMENT_STATUS_PILL_VARIANTS = {
  "not deployed": "default",
  "in progress": "info",
  queued: "pending",
  succeeded: "success",
  failed: "failed",
  timeout: "failed",
  unknown: "warning",
};

const columnHelper = createColumnHelper();

const buildStatusObject = (data, STATUS_MAP) => {
  const BI_MAPPER = {
    is_success: (boolean) => ({ isBiDeployed: String(boolean) }),
    start_timestamp: (timestamp) => ({
      biDeploymentStart: DateTime.fromMillis(timestamp),
    }),
    finish_timestamp: (timestamp) => ({
      biDeploymentFinish: DateTime.fromMillis(timestamp),
    }),
  };

  const DATA_MAPPER = {
    is_success: (boolean) => ({ isDataDeployed: String(boolean) }),
    start_timestamp: (timestamp) => ({
      dataDeploymentStart: DateTime.fromMillis(timestamp),
    }),
    finish_timestamp: (timestamp) => ({
      dataDeploymentFinish: DateTime.fromMillis(timestamp),
    }),
  };

  const SUMMARY_MAPPER = {
    status: (rawStatus) => ({
      rawStatus: rawStatus || "unknown",
      status: STATUS_MAP[rawStatus || "unknown"],
    }),
    message: (string) => ({ message: string }),
    deployment_number: (number) => ({ deploymentNumber: number }),
    start_timestamp: (timestamp) => ({
      licenseDeploymentStart: timestamp ? DateTime.fromMillis(timestamp) : null,
    }),
    finish_timestamp: (timestamp) => ({
      licenseDeploymentFinish: timestamp
        ? DateTime.fromMillis(timestamp)
        : null,
    }),
    last_successful_timestamp: (timestamp) => ({
      licenseLastSuccessfulDeployment: timestamp
        ? DateTime.fromMillis(timestamp)
        : null,
    }),
  };

  const defaultObject = {
    rawStatus: "unknown",
    status: STATUS_MAP["unknown"],
  };

  const biObject = data.bi
    ? Object.keys(data.bi).reduce((object, key) => {
        if (BI_MAPPER[key]) {
          return {
            ...object,
            ...BI_MAPPER[key](data.bi[key]),
          };
        }

        return object;
      }, {})
    : {};

  const dataObject = data.rows
    ? Object.keys(data.rows).reduce((object, key) => {
        if (DATA_MAPPER[key]) {
          return {
            ...object,
            ...DATA_MAPPER[key](data.rows[key]),
          };
        }

        return object;
      }, {})
    : {};

  const licenseObject = data
    ? Object.keys(data).reduce((object, key) => {
        if (SUMMARY_MAPPER[key]) {
          return {
            ...object,
            ...SUMMARY_MAPPER[key](data[key]),
          };
        }

        return object;
      }, {})
    : {};

  return {
    ...defaultObject,
    ...biObject,
    ...dataObject,
    ...licenseObject,
  };
};

const StatusTooltip = ({ data, onCodeClick }) => {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          textAlign: "left",
          width: "100%",
        }}
      >
        <div style={{ position: "relative", whiteSpace: "break-spaces" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4" color="white">
              Summary
            </Typography>
            {onCodeClick && (
              <ViewJsonButton onClick={onCodeClick}>
                <FontAwesomeIcon
                  icon={faBracketsCurly}
                  style={{
                    color: "#fff",
                    border: "1px solid #fff",
                    borderRadius: "100%",
                    padding: "4px",
                  }}
                />
              </ViewJsonButton>
            )}
          </div>
          <Typography variant="p" color="white">
            <strong>Status:</strong> {data.status}
          </Typography>
          {data.message && (
            <Typography variant="p" color="white">
              <strong>Message:</strong> {data.message}
            </Typography>
          )}
          <Typography variant="p" color="white">
            <strong>Start Time:</strong>{" "}
            {data.licenseDeploymentStart?.toFormat(dateTimeFormat)}
          </Typography>
          {data.licenseDeploymentFinish && (
            <Typography variant="p" color="white">
              <strong>Duration:</strong>{" "}
              {data.licenseDeploymentFinish
                .diff(data.licenseDeploymentStart)
                .toFormat(durationFormat)}
            </Typography>
          )}
        </div>
        {["succeeded"].includes(data.rawStatus) && (
          <>
            <div>
              <Typography variant="h4" color="white">
                BI
              </Typography>
              <Typography variant="p" color="white">
                <strong>Success:</strong> {data.isBiDeployed}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Start Time:</strong>{" "}
                {data.biDeploymentStart.toFormat(dateTimeFormat)}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Duration:</strong>{" "}
                {data.biDeploymentFinish
                  .diff(data.biDeploymentStart)
                  .toFormat(durationFormat)}
              </Typography>
            </div>
            <div>
              <Typography variant="h4" color="white">
                Data
              </Typography>
              <Typography variant="p" color="white">
                <strong>Success:</strong> {data.isDataDeployed}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Start Time:</strong>{" "}
                {data.dataDeploymentStart.toFormat(dateTimeFormat)}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Duration:</strong>{" "}
                {data.dataDeploymentFinish
                  .diff(data.dataDeploymentStart)
                  .toFormat(durationFormat)}
              </Typography>
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

const CustomerSegment = (props) => {
  const fetchCustomerSegments = useAuthenticatedCall(getCustomerSegments);
  const { data: selectedCustomerSegmentName } = useQuery({
    queryKey: ["customer-segments", null, {}],
    queryFn: fetchCustomerSegments,
    enabled: !!props.row.original.customer_segment,
    select: (data) => {
      const result = Object.values(data).find(
        (item) => item.id === props.row.original.customer_segment
      )?.name;

      return result;
    },
  });
  return selectedCustomerSegmentName;
};

const OnboardingStatusCell = (props) => {
  const organization = props.row.original;
  const { active: orgIsActive, id: organizationId } = organization;

  const fetchOnboardingCall = useAuthenticatedCall((req) =>
    getOrganizationOnboarding({ ...req, organizationId })
  );
  const { isLoading: isOnboardingFetching, data: onboarding } = useQuery({
    queryKey: ["organization-onboarding", organizationId],
    queryFn: fetchOnboardingCall,
    refetchInterval: 30 * 1000,
    select: (data) => {
      const formattedData = buildStatusObject(data, ONBOARDING_LABELS);

      return {
        ...formattedData,
        tooltip: (
          <StatusTooltip
            data={formattedData}
            onCodeClick={() =>
              props.openModalWithData(
                { onboarding: organization.onboarding },
                organizationId
              )
            }
          />
        ),
      };
    },
  });

  if (isOnboardingFetching) {
    return <Skeleton style={{ width: "50px", height: "18px" }} />;
  }

  return (
    <Pill
      text={
        orgIsActive
          ? ONBOARDING_LABELS[onboarding?.rawStatus]
          : ONBOARDING_LABELS["inactive"]
      }
      size="small"
      variant={
        orgIsActive
          ? ONBOARDING_VARIANTS[onboarding?.rawStatus]
          : ONBOARDING_VARIANTS["inactive"]
      }
      tooltipId={`onboarding-${organizationId}`}
      // tooltipStrategy="fixed"
      tooltipContent={
        orgIsActive
          ? ["succeeded", "timeout", "failed"].includes(onboarding?.rawStatus)
            ? onboarding.tooltip
            : null
          : null
      }
    />
  );
};

const DeploymentStatusCell = (props) => {
  const organization = props.row.original;
  const organizationId = organization.id;

  const fetchDeploymentCall = useAuthenticatedCall((req) =>
    getOrganizationDeployment({ ...req, organizationId })
  );
  const { isLoading: isDeploymentFetching, data: licenseDeployment } = useQuery(
    {
      queryKey: ["organization-deployment", organizationId],
      queryFn: fetchDeploymentCall,
      refetchInterval: 30 * 1000,
      select: (data) => {
        const formattedData = buildStatusObject(data, DEPLOYMENT_STATUS_MAP);

        return {
          ...formattedData,
          tooltip: (
            <StatusTooltip
              data={formattedData}
              onCodeClick={() =>
                props.openModalWithData(
                  {
                    licenses: {
                      license_validation:
                        organization.licenses.license_validation,
                      deployment: organization.licenses.deployment,
                    },
                  },
                  organizationId
                )
              }
            />
          ),
        };
      },
    }
  );

  if (isDeploymentFetching) {
    return <Skeleton style={{ width: "50px", height: "18px" }} />;
  }

  return (
    <Pill
      text={licenseDeployment?.status}
      size="small"
      variant={DEPLOYMENT_STATUS_PILL_VARIANTS[licenseDeployment?.rawStatus]}
      tooltipId={`deployment-${organizationId}`}
      tooltipContent={
        ["succeeded", "timeout", "failed"].includes(
          licenseDeployment?.rawStatus
        )
          ? licenseDeployment?.tooltip
          : null
      }
    />
  );
};

const getOnboardingStatusValue = (status) => {
  switch (status) {
    case "inactive":
      return 1;

    case "not onboarded":
      return 2;

    case "failed":
      return 3;

    case "timeout":
      return 4;

    case "queued":
      return 5;

    case "in progress":
      return 6;

    case "succeeded":
      return 7;

    case "unknown":
    default:
      return 0;
  }
};

const getDeploymentStatusValue = (status) => {
  switch (status) {
    case "not deployed":
      return 1;

    case "failed":
      return 2;

    case "timeout":
      return 3;

    case "queued":
      return 4;

    case "in progress":
      return 5;

    case "succeeded":
      return 6;

    case "unknown":
    default:
      return 0;
  }
};

const CheckBox = ({ checked }) => {
  return (
    <FontAwesomeIcon
      icon={faCheckSquare}
      size="lg"
      style={{
        "--fa-primary-color": "#fff",
        "--fa-secondary-color": "#036dcc",
        "--fa-secondary-opacity": 1,
        visibility: checked ? "visible" : "hidden",
      }}
    />
  );
};

const staticColumns = [
  columnHelper.accessor("name", {
    header: "Name",
    sortingFn: "textCaseSensitive",
    size: 300,
    customStyles: {
      minWidth: 225,
      flexGrow: 3,
    },
    cell: (props) => {
      const orgId = props.row.original.id;
      const populiOrg = props.row.original.id === "populi";

      return (
        <TextWithIconTableCell
          position="left"
          icon={populiOrg ? faBuildingUser : faBuilding}
          primaryColor="#D68712"
        >
          <Link
            to={`/admin/org-management/${orgId}/update`}
            style={{
              fontSize: "14px",
              flexGrow: 1,
              overflowX: "clip",
              textOverflow: "ellipsis",
            }}
            state={{ from: "OrgUsers", orgId: orgId }}
          >
            <SearchableTableCell
              searchConfig={props.table.options.meta.searchConfig}
              columnId={props.column.id}
              placeholder="Missing name"
            >
              {props.getValue()}
            </SearchableTableCell>
          </Link>
        </TextWithIconTableCell>
      );
    },
  }),
  columnHelper.accessor("id", {
    header: "ID",
    size: 150,
    customStyles: {
      minWidth: 125,
    },
    cell: SearchableTableCell,
  }),
  columnHelper.accessor((row) => row.customer_segment, {
    id: "segment",
    size: 130,
    customStyles: {
      minWidth: 130,
    },
    header: () => {
      return (
        <HeaderWithTooltip
          info={<CustomerSegmentTooltip listStyle={{ padding: 5 }} />}
          clickable={true}
        >
          Segment
        </HeaderWithTooltip>
      );
    },
    cell: CustomerSegment,
  }),
  columnHelper.accessor((row) => row, {
    id: "medicare",
    header: "Medicare",
    size: 100,
    customStyles: {
      minWidth: 95,
    },
    cell: (props) => {
      return (
        <Row style={{ justifyContent: "center" }}>
          <CheckBox checked={props.row.original?.medicare_licensed} />
        </Row>
      );
    },
  }),
  columnHelper.accessor((row) => row.licenses?.user_limit || 0, {
    id: "userLimit",
    header: "User Limit",
    size: 100,
    customStyles: {
      minWidth: 100,
    },
    sortDescFirst: false,
    cell: (props) => {
      const { licenses, userCount } = props.row.original;
      const assigned = userCount?.total;
      const available = licenses?.user_limit || 0;

      if (available === 0) {
        return "—";
      }

      return `${assigned} / ${available}`;
    },
  }),
];

const OrganizationTable = (props) => {
  const navigate = useNavigate();
  const { data, isRefreshing, searchFilter, isLoading, canUpdate } = props;

  const [orgToDeactivate, setOrgToDeactivate] = useState(null);
  const [orgToActivate, setOrgToActivate] = useState(null);
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [jsonRootName, setJsonRootName] = useState(false);

  const openModalWithData = (data, rootName) => {
    setJsonRootName(rootName);
    setModalContent(data);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setJsonRootName(false);
    setModalContent({});
    setModalIsOpen(false);
  };

  const dynamicColumns = useMemo(() => {
    return [
      columnHelper.accessor(
        (row) => (row.active ? row.onboarding?.status : "inactive"),
        {
          id: "onboarding",
          header: "Onboarding",
          size: 150,
          customStyles: {
            minWidth: 125,
          },
          cell: (props) => (
            <OnboardingStatusCell
              {...props}
              openModalWithData={openModalWithData}
            />
          ),
          sortingFn: (rowA, rowB, columnId) => {
            const statusValueA = getOnboardingStatusValue(
              rowA.original.active
                ? rowA.original.onboarding?.status
                : "inactive"
            );
            const statusValueB = getOnboardingStatusValue(
              rowB.original.active
                ? rowB.original.onboarding?.status
                : "inactive"
            );

            if (statusValueA < statusValueB) {
              return -1;
            }

            if (statusValueA > statusValueB) {
              return 1;
            }

            return 0;
          },
        }
      ),
      columnHelper.accessor("deployment", {
        id: "deployment",
        header: "Deployment",
        size: 150,
        customStyles: {
          minWidth: 125,
        },
        cell: (props) => (
          <DeploymentStatusCell
            {...props}
            openModalWithData={openModalWithData}
          />
        ),
        sortDescFirst: false,
        sortingFn: (rowA, rowB, columnId) => {
          const statusValueA = getDeploymentStatusValue(
            rowA.original.licenses?.deployment?.status
          );
          const statusValueB = getDeploymentStatusValue(
            rowB.original.licenses?.deployment?.status
          );

          if (statusValueA < statusValueB) {
            return -1;
          }

          if (statusValueA > statusValueB) {
            return 1;
          }

          return 0;
        },
      }),
      columnHelper.accessor(
        (row) => row.licenses?.deployment?.last_successful_timestamp || 0,
        {
          id: "deploymentDate",
          header: "Last Deployment",
          size: 200,
          customStyles: {
            minWidth: 150,
          },
          cell: (info) => {
            const value = info.getValue();

            if (value === 0) {
              return "—";
            }

            return DateTime.fromMillis(value).toFormat("ff");
          },
          sortDescFirst: false,
          sortingFn: (rowA, rowB, columnId) => {
            const dateValueA = rowA.getValue(columnId);
            const dateValueB = rowB.getValue(columnId);

            if (dateValueA < dateValueB) {
              return -1;
            }

            if (dateValueA > dateValueB) {
              return 1;
            }

            return 0;
          },
        }
      ),
      columnHelper.display({
        id: "actions",
        size: 65,
        customStyles: {
          minWidth: 65,
          justifyContent: "flex-end",
          flex: "0 1 65px",
          display: "flex",
        },
        cell: ({ row }) => {
          const openRowMenuId = row.original.id;
          const openRowMenuOrg = data.find((org) => org.id === openRowMenuId);

          return (
            <Popover
              placement="bottom-end"
              strategy="fixed"
              offset={3}
              actions={[
                {
                  name: "Edit",
                  onClick: () => {
                    navigate(
                      `/admin/org-management/${openRowMenuId}/update/general`,
                      {
                        state: {
                          from: "Organizations",
                          orgId: openRowMenuId,
                        },
                      }
                    );
                  },
                },
                {
                  name: "Manage Users",
                  onClick: () =>
                    navigate(
                      `/admin/org-management/${openRowMenuId}/update/users`
                    ),
                },
                openRowMenuId !== "populi"
                  ? openRowMenuOrg?.active
                    ? {
                        name: "Deactivate",
                        onClick: () => setOrgToDeactivate(openRowMenuOrg),
                      }
                    : {
                        name: "Activate",
                        onClick: () => setOrgToActivate(openRowMenuOrg),
                      }
                  : null,
              ].filter((v) => !!v)}
              TriggerComponent={(props) => {
                const { setRef, email, name, isNavOpen, ...triggerProps } =
                  props;

                return (
                  <div
                    style={{
                      width: "50px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faEllipsis}
                      className="actions-menu"
                      style={{
                        width: "100%",
                        fontSize: "19px",
                        cursor: "pointer",
                      }}
                      ref={setRef}
                      {...triggerProps}
                    />
                  </div>
                );
              }}
            />
          );
        },
      }),
    ];
  }, [data, navigate]);

  const columnVisibility = useMemo(
    () => ({
      actions: canUpdate,
    }),
    [canUpdate]
  );

  return (
    <>
      <Table
        data={data}
        columns={staticColumns.concat(dynamicColumns)}
        isRefreshing={isRefreshing}
        searchConfig={searchFilter}
        isLoading={isLoading}
        columnVisibility={columnVisibility}
        isVirtualized={true}
      />

      {orgToDeactivate ? (
        <DeactivateOrganizationModal
          organization={orgToDeactivate}
          close={() => {
            setOrgToDeactivate(null);
          }}
        />
      ) : null}

      {orgToActivate ? (
        <ActivateOrganizationModal
          organization={orgToActivate}
          close={() => {
            setOrgToActivate(null);
          }}
        />
      ) : null}
      <Modal
        visible={isModalOpen}
        dismiss={closeModal}
        title="JSON Viewer"
        style={{
          width: "60vw",
          marginTop: "5vh",
        }}
      >
        <JSONContainer>
          <ReactJsonView
            name={jsonRootName}
            src={modalContent}
            collapsed={4}
            displayObjectSize={false}
            displayDataTypes={false}
            quotesOnKeys={false}
            displayArrayKey={false}
          />
        </JSONContainer>
      </Modal>
    </>
  );
};

export default OrganizationTable;

import styled from "styled-components";
import { components } from "react-select";

export const StyledControl = styled(components.Control)`
  gap: 5px;
  background-color: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 16px;

  ${(props) =>
    props.selectProps.size === "small" &&
    `
    padding-top: 1px;
    padding-bottom: 0px;
    padding-left: 6px;
    padding-right: 10px;
    min-height: 27px !important;
  `}

  ${(props) =>
    props.selectProps.errorMessage &&
    `
    border-color: #BA351C;
  `}

  ${(props) =>
    (props.menuIsOpen || props.isFocused) &&
    `
      border-color: #015fcc;
      min-height: 20px;

      &:hover {
        border-color: "#015fcc",
      }
  `}


  ${(props) =>
    props.isDisabled &&
    `
    background-color: #E4E4E4;
  `}
`;

export const StyledPlaceholder = styled(components.Placeholder)`
  color: #9f9f9f;
  font-size: 16px;
  font-family: Inter;

  ${(props) =>
    props.isFocused &&
    `
    display: none;
  `}}

  ${(props) =>
    props.selectProps.size === "small" &&
    `
      font-size: 12px;
  `}}

    ${(props) =>
      props.selectProps.size === "large" &&
      `
      height: 20px;
  `}}
`;

export const StyledInput = styled(components.Input)`
  font-family: Inter;
`;

export const StyledNoOptionsMessage = styled(components.NoOptionsMessage)`
  font-family: Inter;
  padding: 10px;
`;

export const StyledSingleValue = styled(components.SingleValue)`
  color: #3e3e3c;
  font-size: 16px;
  font-family: Inter;

  ${(props) =>
    props.isDisabled &&
    `
    background-color: #3E3E3C;
  `}

  ${(props) =>
    props.selectProps.size === "small" &&
    `
    font-size: 12px;
  `}

    ${(props) =>
    props.selectProps.size === "large" &&
    `
      height: 20px;
  `}
`;

export const StyledIndicatorsContainer = styled(components.IndicatorsContainer)`
  align-self: start !important;
  align-items: center !important;
  margin-top: 1px;

  ${(props) =>
    props.selectProps.size === "small" &&
    `
    svg {
      margin-top: 2px;
    }
  `}
`;

export const StyledDropdownIndicator = styled(components.DropdownIndicator)`
  color: #706e6b;
  cursor: pointer !important;
`;

export const StyledClearIndicator = styled(components.ClearIndicator)`
  color: #706e6b;
  cursor: pointer !important;
  margin-left: 9px;
  margin-right: 9px;
  margin-bottom: -1px;

  svg {
    padding: 1px 6px;
    border-radius: 100%;

    &:hover {
      background-color: #f5f2fc;
    }
  }

  ${(props) =>
    props.selectProps.size === "small" &&
    `
    margin-left: 5px;
    margin-right: 5px;

    svg {
      margin-top: 0px;
    }
  `}
`;

export const StyledIndicatorSeparator = styled(components.IndicatorSeparator)`
  display: none;
`;

export const StyledMenu = styled(components.Menu)`
  padding-top: 3px;
  padding-bottom: 4px;
  background-color: #ffffff;
  border: 1px solid #dddbda;
  border-radius: 4px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
  margin-top: 2px;
`;

export const StyledMenuList = styled(components.MenuList)``;

export const StyledOption = styled.div`
  display: flex !important;
  font-size: 12px !important;
  gap: 8px;
  align-items: center;
  color: #2b2826;
  font-family: Inter;
  background-color: #ffffff;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer !important;
  font-weight: unset;

  svg {
    display: block;
    color: #007eac;
    height: 18px;
    width: 18px;
    box-sizing: border-box !important;
    align-self: flex-start;
  }

  &:hover {
    background-color: #f5f2fc;
  }

  ${(props) =>
    props.isFocused &&
    `
    background-color: #F5F2FC;
  `}

  ${(props) =>
    props.isSelected &&
    `
    // font-weight: bold;
    // background-color: #c7d8f0;


      svg {
        visibility: visible;
      }

      &:hover {
        // background-color: #c7d8f0;
      }
  `}

  ${(props) =>
    !props.isSelected &&
    `
    padding-top: 10px;

  `}



  ${(props) =>
    props.isGroupOption &&
    `
    font-weight: bold;
  `}

  ${(props) =>
    props.isChildOption &&
    `
    font-weight: normal;
    padding-left: 36px;
  `}

  ${(props) =>
    props.isChildOption &&
    !props.isMulti &&
    `
    padding-left: 12px;
  `}

  ${(props) =>
    props.selectProps.size === "small" &&
    `
    padding-top: 7px;
    padding-bottom: 7px;

    svg {
      height: 14px;
      width: 14px;
    }
  `}
`;

export const ItemSpacer = styled.div`
  align-self: flex-start;
  flex-shrink: 0;

  ${(props) =>
    props?.iconProps?.size === "small" &&
    `
      height: 14px;
      width: 14px;
  `}

  ${(props) =>
    props?.iconProps?.size === "large" &&
    `
      height: 17px;
      width: 17px;
  `}
`;

export const EmptyCheckbox = styled(ItemSpacer)`
  align-self: flex-start;
  flex-shrink: 0;
  border: 1px solid #dddbda;
  border-radius: 3px;
  background-color: #ffffff;
  width: 15px;
  height: 15px;
`;

export const StyledValueContainer = styled(components.ValueContainer)`
  display: flex !important;
  align-items: center !important;
  flex-wrap: ${(props) => (props.isMulti ? "wrap" : "nowrap")} !important;
  transition: height 300ms cubic-bezier(0.87, 0, 0.13, 1);
  overflow: hidden;
  min-height: 20px;

  ${(props) =>
    props.isMulti &&
    props.size === "large" &&
    `
      min-height: 100px;
  `}

  ${(props) =>
    (props.menuIsOpen || props.isFocused) &&
    `
      min-height: 20px;
  `}
`;

export const AnimatedMultiValueList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  min-height: 20px;

  ${(props) =>
    props.size === "small" &&
    `
      gap: 3px;

  `}
`;

export const SecondaryButton = styled.div`
  font-size: 12px;
  font-family: Inter;
  text-align: center;
  color: #541299;
  padding: 2px 6px;
  padding-top: 2px;
  border: 1px solid #dddbda;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #f0e9f0;
    border-color: #f0e9f0;
  }
`;

import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { animate } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import { faLockKeyhole } from "@fortawesome/pro-regular-svg-icons";
import Typography from "core/components/Typography";
import { StatusIcon } from "./styles";
import { motion } from "framer-motion";

export const SubNav = ({
  className,
  to,
  icon,
  label,
  statusIcon,
  statusVariant,
  isStatusLoading,
  isDisabled,
  locationState = { from: "" },
}) => {
  const resetScroll = () => {
    animate(window.scrollY, 0, {
      duration: 0.5,
      onUpdate: (scrollY) => window.scrollTo(window.scrollX, scrollY),
    });
  };

  const location = useLocation();
  const isActive = String(location.pathname).includes(to);

  return (
    <li
      className={isActive ? "active" : ""}
      style={
        isDisabled
          ? {
              opacity: 0.5,
              pointerEvents: "none",
            }
          : {}
      }
    >
      {isActive && (
        <motion.div
          key={to}
          layoutId="activeBackground"
          style={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            height: "100%",
            backgroundColor: "#d5c0d4",
            borderRadius: "5px",
          }}
        />
      )}
      <Link
        to={to}
        state={locationState}
        onClick={() => resetScroll()}
        style={{ position: "relative", zIndex: 2 }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: "100%",
          }}
        >
          <div
            style={{
              textAlign: "center",
              color: "#2B1850",
            }}
          >
            <FontAwesomeIcon icon={icon} width={20} />
          </div>
          <Typography
            variant="p"
            color="text-blue"
            noMargin
            style={{ flexGrow: 1 }}
          >
            {label}
          </Typography>
          <div
            style={{
              textAlign: "center",
            }}
          >
            {isStatusLoading ? (
              <Skeleton
                style={{ width: "20px", height: "20px", borderRadius: "20px" }}
              />
            ) : isDisabled ? (
              <FontAwesomeIcon icon={faLockKeyhole} />
            ) : (
              statusIcon && (
                <StatusIcon
                  icon={isDisabled ? faLockKeyhole : statusIcon}
                  variant={statusVariant}
                  style={{ alignSelf: "flex-end" }}
                />
              )
            )}
          </div>
        </div>
      </Link>
    </li>
  );
};

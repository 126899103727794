import { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { animated, useSpring, useTransition } from "react-spring";
import {
  useIsRestoring,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import DocumentTitle from "react-document-title";
import { DateTime } from "luxon";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { faClose } from "@fortawesome/pro-regular-svg-icons";

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import Navigation from "core/components/Navigation";
import useCurrentUser from "core/hooks/useCurrentUser";
import useHasPermissions from "core/hooks/useHasPermissions";
import { FLAG_ENTITIES, RELEASE_STAGES, PERMISSIONS } from "core/constants";
import {
  getAdminApiPermission,
  getProductCatalog,
  getProductCatalogVersion,
  getUsers,
} from "modules/admin/actions";
import Typography from "./Typography";
import { SecondaryButton } from "./QueryBuilder/styles";
import Loader from "core/components/Loader";
import { getLastActiveCredential } from "modules/admin/utilities";
import { useFeatureFlaggingState } from "core/hooks/featureFlagging";
import { ENVIRONMENTS, getEnv } from "common/utilities";
import useToaster from "core/hooks/useToaster";

const BannerContainer = styled.div`
  background-color: #0097c0;
  color: #fff;
  font-family: "Inter";
  text-align: center;
  padding-top: 6px;
  padding-bottom: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;

  ${(props) =>
    props.variant === "warning" &&
    `
    background-color: #d68712;
    color: #fff
  `}

  ${(props) =>
    props.variant === "error" &&
    `
    background-color: #BA351C;
    color: #fff;
  `}
`;

const catalogQueryKey = [
  "catalog",
  null,
  {
    include: ["models", "data", "fields", "field_values"],
    // Currently only supporting single value filter,
    // Arrays combine objects as "AND"
    filters: [
      {
        catalog: "products",
        field: "is_license_required",
        operator: "=",
        value: true,
      },
    ],
  },
];

// Matt: Intentionally co-locating for now, but likely moving to core in future
const Banner = ({ isVisible, variant, onDismiss, children }) => {
  const containerHeight = useSpring({
    height: isVisible ? "30px" : "0px",
    delay: isVisible ? 675 : 0,
    position: "sticky",
    top: "0px",
    zIndex: 2000,
  });

  const transitions = useTransition([isVisible], {
    from: { position: "relative", zIndex: 2000, y: -30, width: "100%" },
    enter: [{ y: 0 }],
    leave: { position: "relative", zIndex: 2000, y: -30, width: "100%" },
    exitBeforeEnter: true,
  });

  return (
    <animated.div
      style={{ backgroundColor: "#2f2e41", height: "0px", ...containerHeight }}
    >
      {transitions((style, isVisible) => {
        return isVisible ? (
          <BannerContainer variant={variant} as={animated.div} style={style}>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "40px 1fr 40px",
              }}
            >
              <div />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {children}
              </div>
              {onDismiss && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    size="lg"
                    onClick={onDismiss}
                  />
                </div>
              )}
            </div>
          </BannerContainer>
        ) : null;
      })}
    </animated.div>
  );
};

let hasCheckedCatalogVersion = false;

const LoadWhileRestoringLocalStorage = ({ children }) => {
  const isRestoring = useIsRestoring();

  if (isRestoring) {
    return <Loader style={{ paddingBottom: "20vh" }} />;
  }

  return children;
};

const ProtectedLayout = ({
  pageTitle,
  backLinkText,
  backLinkTo,
  showBackLink,
  children,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { orgId: orgIdParam } = useParams();
  const { updateEntityContext } = useFeatureFlaggingState();

  useEffect(() => {
    let totalSize = 0;

    for (let key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        let keySize = new Blob([key]).size; // Size of the key
        let valueSize = new Blob([localStorage[key]]).size; // Size of the value
        totalSize += keySize + valueSize;
      }
    }

    console.info(
      `Total size in MB: ${(totalSize / (1024 * 1024)).toFixed(2)} MB`
    );
  }, []);

  useEffect(() => {
    if (orgIdParam) {
      const organizationFeatureContext = {
        entity: FLAG_ENTITIES.ORGANIZATION,
        key: orgIdParam,
        availableStages:
          orgIdParam === "populi"
            ? [RELEASE_STAGES.BETA, RELEASE_STAGES.GA]
            : [RELEASE_STAGES.GA],
      };

      updateEntityContext(organizationFeatureContext);
    }
  }, [orgIdParam, updateEntityContext]);

  const { currentUser } = useCurrentUser();
  const { role, email, organizationId } = currentUser;
  const { toaster } = useToaster();

  useEffect(() => {
    if ([ENVIRONMENTS.DEV, ENVIRONMENTS.QA].includes(getEnv())) {
      const hasSeen = localStorage.getItem("secret-messages-seen");
      if (
        !hasSeen &&
        [
          // "matt.peck@populi.ai",
          "david.simosa@populi.ai",
          "david.chen@populi.ai",
          "david.chen2@populi.ai",
          "vincent.roberto@populi.ai",
          "savitha.keerthikumar@populi.ai",
          "kevin.cote@populi.ai",
          "dan.pagliarello@populi.ai",
          "emily.dalo@populi.ai",
          "kathryn.bennett@populi.ai",
          "leah.shea@populi.ai",
        ].includes(currentUser.email)
      ) {
        const firstName = currentUser.name.split(" ")[0];

        toaster.basic({
          title: "Message",
          message: `👋🏼 Hi ${firstName}! How are you doing today?`,
          isInfinite: true,
          containerId: "secret-messages",
          onDismiss: () => {
            setTimeout(() => {
              toaster.error({
                title: "Message",
                message: `😠 ${firstName}! You didn't say hi back!`,
                isInfinite: true,
                containerId: "secret-messages",
                onDismiss: () => {
                  setTimeout(() => {
                    toaster.basic({
                      title: "Message",
                      message: "👿 I'm beginning to feel dismissed...",
                      duration: 3000,
                      containerId: "secret-messages",
                      onDismiss: () => {
                        setTimeout(() => {
                          toaster.success({
                            title: "Message",
                            message: `Ok, you win! Have a great day ${firstName}...`,
                            duration: 3000,
                            containerId: "secret-messages",
                            onDismiss: () => {
                              localStorage.setItem(
                                "secret-messages-seen",
                                true
                              );
                            },
                          });
                        }, 1000);
                      },
                    });
                  }, 1000);
                },
              });
              toaster.info({
                title: "Message",
                message: "I'm waiting...",
                isInfinite: true,
                containerId: "secret-messages",
              });
            }, 1000);
          },
        });
      }
    }
  }, [currentUser.email, toaster, currentUser.name]);

  const fetchUserCall = useAuthenticatedCall(getUsers);

  const queryKey = ["users", organizationId, { email }];
  const { data: users } = useQuery({
    queryKey,
    queryFn: fetchUserCall,
  });

  const user = useMemo(() => {
    return {
      ...users?.[0],
    };
  }, [users]);
  const lastActiveCredential = getLastActiveCredential(user.data_access_info);
  const isCredentialExpiring = lastActiveCredential
    ? lastActiveCredential.days_to_expire <= 7
    : false;

  // Fetch product catalog
  const fetchCatalog = useAuthenticatedCall(getProductCatalog);
  const { data: productCatalog } = useQuery({
    queryKey: catalogQueryKey,
    queryFn: fetchCatalog,
    staleTime: Infinity,
    enabled: useHasPermissions(
      PERMISSIONS[role],
      getAdminApiPermission("catalog")
    ),
  });

  const fetchCatalogVersion = useAuthenticatedCall(getProductCatalogVersion);
  const { data: productCatalogVersion } = useQuery({
    queryKey: ["catalog-version"],
    queryFn: fetchCatalogVersion,
    enabled: useHasPermissions(
      PERMISSIONS[role],
      getAdminApiPermission("catalog-version")
    ),
  });

  // Check if catalog version changed
  useEffect(() => {
    if (
      !hasCheckedCatalogVersion &&
      productCatalogVersion?.version &&
      productCatalog &&
      Object.keys(productCatalog).length > 0
    ) {
      hasCheckedCatalogVersion = true;

      if (
        productCatalogVersion.version !==
        productCatalog[Object.keys(productCatalog)[0]].version
      ) {
        queryClient.invalidateQueries({ queryKey: catalogQueryKey });
      }
    }
  }, [productCatalog, productCatalogVersion, queryClient]);

  const [isExpirationBannerDismmissed, setExpirationBannerDismmissed] =
    useState(() => {
      const now = DateTime.now();
      const localValue = localStorage.getItem("dateExpirationBannerDismmissed");

      if (localValue) {
        const isToday = now.hasSame(DateTime.fromISO(localValue), "day");

        return isToday;
      }

      return false;
    });

  const handleDismissExpirationBanner = () => {
    localStorage.setItem(
      "dateExpirationBannerDismmissed",
      DateTime.now().toISO()
    );
    setExpirationBannerDismmissed(true);
  };

  return (
    <>
      <DocumentTitle
        title={pageTitle ? `Populi | ${pageTitle}` : "Populi Platform"}
      />

      <div style={{ display: "flex", height: "100%" }}>
        <Navigation />
        <main
          style={{
            flex: 1,
            height: "100%",
            overflow: "auto",
            position: "relative",
          }}
        >
          {/* Data Access Credential Expiration Banner */}
          <Banner
            isVisible={isCredentialExpiring && !isExpirationBannerDismmissed}
            variant={
              lastActiveCredential?.days_to_expire <= 3 ? "error" : "warning"
            }
            onDismiss={handleDismissExpirationBanner}
          >
            <div style={{ marginRight: "5px" }}>
              {lastActiveCredential?.days_to_expire <= 0
                ? "Your Data Access Credential has expired!"
                : `Your Data Access Credential will expire in ${lastActiveCredential?.days_to_expire} days.`}
            </div>

            <div
              onClick={() =>
                navigate(
                  `/admin/org-management/${organizationId}/users/${email}/profile/credentials`
                )
              }
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Get New Credential
            </div>
          </Banner>
          <>
            {showBackLink && backLinkText && backLinkTo ? (
              <>
                <div style={{ marginTop: "30px" }} />
                <div
                  style={{
                    position: "absolute",
                    zIndex: 1000,
                    top:
                      isCredentialExpiring && !isExpirationBannerDismmissed
                        ? "54px"
                        : "24px",
                    left: "34px",
                    transition: "top 0.25s ease-in-out",
                  }}
                >
                  <SecondaryButton
                    onClick={() => {
                      navigate(backLinkTo, {
                        state: {
                          from: "Organizations",
                        },
                      });
                    }}
                    style={{ display: "flex", width: "fit-content" }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={{ marginRight: "5px", marginBottom: "-1px" }}
                    />

                    <Typography variant="sub-text" noMargin>
                      {backLinkText}
                    </Typography>
                  </SecondaryButton>
                </div>
              </>
            ) : null}

            <LoadWhileRestoringLocalStorage>
              {children}
            </LoadWhileRestoringLocalStorage>
          </>
        </main>
      </div>
    </>
  );
};

export default ProtectedLayout;

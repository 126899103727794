import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const FieldControlContainer = styled.div`
  flex-grow: 1;
`;

export const FieldControlSkeleton = styled.div`
  height: 41px;
  border-radius: 4px;
  border: 1px solid #dddbda;
  padding: 10px 8px;
  background-color: #ffffff;

  span {
    border-radius: 2px;
    height: 100%;
    display: flex;
  }

  ${(props) =>
    props.size === "small" &&
    `
    height: 27px;
    padding: 0px;
    border: unset;

    span {
      display: block;
    }
  `}

  ${(props) =>
    props.type === "textarea" &&
    props.size !== "small" &&
    `
    height: 204px;

  `}

  ${(props) =>
    props.type === "search" &&
    props.size !== "small" &&
    `
    height: unset;

    span {
    border-radius: 4px;
  }

  `}

      ${(props) =>
    props.type === "combobox" &&
    props.size !== "small" &&
    `
    height: 20px;
    padding: 9px 8px 10px 8px;


  `}

  ${(props) =>
    props.type === "text" &&
    props.size !== "small" &&
    `
    height: 19px;
    `}
`;

export const LabelText = styled.label`
  display: block;
  text-align: left;
  font-size: 0.75rem;
  font-weight: ${(props) => (props.normal ? "normal" : "600")};
  font-family: Inter, sans-serif;
  color: #000000;
  position: relative;

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}

  ${(props) =>
    props.height &&
    `
    height: ${(props) => props.height};
  `}
`;

export const LabelRequired = styled.span`
  color: #ff2a00;
`;

export const LabelOptional = styled.span`
  font-weight: normal;
  font-style: italic;
`;

export const TextInput = styled.input`
  box-sizing: border-box;
  height: 41px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dddbda;
  background-color: white;
  padding: 4px 10px;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  outline: none;

  &:focus {
    border: 1px solid #015fcc;
  }

  // Hacks autofill background style overrides
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
  }

  &:disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }

  // Search related styles
  &[type="search"] {
    outline-offset: 0px;

    ::-webkit-search-cancel-button {
      cursor: pointer;
      font-size: 15px;
      margin-right: 5px;
      margin-bottom: 1px;
      -webkit-appearance: none;

      ${(props) => props.cancelButtonStyles}
    }

    ::-webkit-textfield-decoration-container {
      gap: 10px;
    }
  }

  // Number type related styles
  &[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  // Small variant
  ${(props) =>
    props.variant === "small" &&
    `
    height: 27px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    padding-left: 10px;
  `}

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}

  ${(props) =>
    props.readOnly &&
    `
    pointer-events: none;
    border: none;
    padding-left: 0px;
    height: 22px;
    background-color: inherit;
  `}

  // Error
  ${(props) =>
    props.error &&
    `
    border-color: #ff2a00;
  `}
`;

export const MultiContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  background-color: white;
  padding: 10px 12px;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  outline: none;
  border-radius: 4px;
  border: 1px solid #dddbda;

  &:focus,
  &:has(input:focus) {
    border: 1px solid #015fcc;
  }

  ${(props) =>
    props.variant === "small" &&
    `

    padding-left: 6px;
    padding-top: 5px;
    padding-bottom: 5px;
  `}

  // Disabled styles
  ${(props) =>
    props.isDisabled &&
    `
    opacity: 0.5;
  `}

  // Error
  ${(props) =>
    props.hasError &&
    `
    border-color: #ff2a00;
    padding-right: 60px;
  `}

  ${(props) =>
    props.hasError &&
    props.variant === "small" &&
    `
    padding-right: 48px;
  `}
`;

export const ChipContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

export const ValuesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const MultiTextInput = styled.input`
  flex: 1 0 150px;
  background-color: white;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  outline: none;
  border: none;

  // Hacks autofill background style overrides
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
  }

  // Number type related styles
  &[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  // Small variant
  ${(props) =>
    props.variant === "small" &&
    `
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;

  `}
`;

export const SearchContainer = styled.div`
  position: relative;
  width: 250px;

  ${(props) =>
    props.variant === "small" &&
    `
    width: 200px;
    `}
`;

export const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  width: 14px;
  height: 14px;
  color: #706e6b;
  font-weight: light;
  right: 15px;
  top: 14px;

  ${(props) =>
    props.variant === "small" &&
    `
    width: 12px;
    height: 12px;
    right: 10px;
    top: 7px;
  `}

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  `}
`;

export const ResetIcon = styled(FontAwesomeIcon)`
  position: absolute;
  width: 14px;
  height: 14px;
  color: #706e6b;
  font-weight: light;
  right: 15px;
  top: 14px;
  cursor: pointer;

  ${(props) =>
    props.variant === "small" &&
    `
    width: 12px;
    height: 12px;
    right: 10px;
    top: 7px;
  `}
`;

export const InputErrorIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 11px;
  right: 12px;
  color: #ff2a00;
  width: 20px;
  height: 20px;

  ${(props) =>
    props.variant === "small" &&
    `
    top: 5px;
    right: 6px;
    width: 16px;
    height: 16px;
  `}

  ${(props) =>
    props.type === "textarea" &&
    `
    top: unset;
    right: unset;
    bottom: 4px;
  `}

    ${(props) =>
    props.type === "textarea" &&
    props.variant === "small" &&
    `
    top: unset;
    right: unset;
    bottom: 8px;
  `}
`;

export const PasswordShowIcon = styled(FontAwesomeIcon)`
  position: absolute;
  height: 18px;
  top: 1px;
  right: 11px;
  cursor: pointer;
`;

export const PasswordHideIcon = styled(FontAwesomeIcon)`
  position: absolute;
  height: 18px;
  top: 1px;
  right: 10px;
  cursor: pointer;
`;

export const NumberFieldContainer = styled.div`
  position: relative;
`;

export const NumberIconContainer = styled.div`
  position: absolute;
  display: flex;
  right: 15px;
  gap: 15px;
  height: 41px;
  top: 0px;
  align-items: center;

  // Small variant
  ${(props) =>
    props.variant === "small" &&
    `
    height: 25px;
    right: 5px;
    gap: 8px;
  `}
`;

export const NumberIcon = styled(FontAwesomeIcon)`
  cursor: pointer;

  // Disabled styles
  ${(props) =>
    props.disabled &&
    `
    cursor: default;
    opacity: 0.5;
    color: #706E6B;
  `}

  // Small variant
  ${(props) =>
    props.variant === "small" &&
    `
    width: 16px;
    height: 16px;
  `}
`;

export const ClearValueButton = styled.div`
  color: #706e6b;
  margin-bottom: -1px;
  cursor: pointer;

  svg {
    padding: 3px 6px;
    border-radius: 100%;

    &:hover {
      background-color: #f5f2fc;
    }
  }

  ${(props) =>
    props.variant === "small" &&
    `
    top: 2px;
      right: 6px;
  `}

  ${(props) =>
    props.hasError &&
    `
      right: 36px;
    `}

    ${(props) =>
    props.hasError &&
    props.variant === "small" &&
    `
      right: 24px;
    `}
`;

export const FieldHintContainer = styled.div`
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: absolute;
  top: 1px;
  right: -88px;
`;

export const FieldHintPrimary = styled.div`
  visibility: hidden;
  opacity: 0;
  position: relative;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 3px;
  transition: visibility 0s, opacity 0.5s ease-in-out;

  ${(props) =>
    props.isVisible &&
    `
      visibility: visible;
      opacity: 1;
  `};
`;

export const FieldHintSecondary = styled.div`
  visibility: hidden;
  opacity: 0;
  position: relative;
  width: fit-content;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 3px;
  transition: visibility 0s, opacity 0.5s ease-in-out;

  ${(props) =>
    props.isVisible &&
    `
      visibility: visible;
      opacity: 1;
  `};
`;

export const FieldContainer = styled.div`
  min-width: fit-content;
`;

export const FieldError = styled.div`
  font-family: "Inter";
  color: #ff2a00;
  font-size: 12px;
  text-align: right;
  padding: 2px;
  min-height: 22px;
  line-height: 14px;

  // Temporary inline control fix
  ${(props) =>
    props.isHidden &&
    `
    display: none;
  `}

  ${(props) =>
    props.type === "textarea" &&
    `
  left: 22px;
  position: relative;
  top: 3px;
  text-align: left;
  `}

  ${(props) =>
    props.type === "textarea" &&
    props.variant === "small" &&
    `
    left: 18px;
    position: relative;
    top: 0px;
    text-align: left;
  `}
`;

export const Textarea = styled.textarea`
  display: block;
  min-width: -webkit-fill-available;
  min-height: 41px;
  padding: 10px;
  border: 1px solid #dddbda;
  border-radius: 4px;

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}

  ${(props) =>
    props.readOnly &&
    `
    pointer-events: none;
    border: none;
  `}

    ${(props) =>
    props.variant === "small" &&
    `
    font-size: 13px;
    letter-spacing: 0;
    line-height: 14px;
    min-height: 27px;
  `}

  ${(props) =>
    props.error &&
    `
    border-color: #ff2a00;
    padding-right: 36px;
  `};
`;

export const InputSkeletonContainer = styled.div`
  height: 41px;
  border-radius: 4px;
  border: 1px solid #dddbda;
  padding: 10px 8px;
  background-color: #ffffff;
  margin-bottom: 22px;

  span {
    border-radius: 2px;
    height: 100%;
  }

  ${(props) =>
    props.size === "small" &&
    `
    height: 27px;
    padding: 0px;
    border: unset;

    span {
      display: block;
    }
  `}
`;

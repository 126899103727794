import styled from "styled-components";

export const ViewJsonButton = styled.div`
  position: relative;
  margin-left: 10px;
  z-index: 100;
  cursor: pointer;

  & > svg {
    margin-left: 0px !important;

    :hover {
      background-color: #6842c0;
    }
  }
`;

export const JSONContainer = styled.div`
  text-align: left;
  background-color: #fff;
  font-family: Inter;
  letter-spacing: 0.6px;
  font-size: 16px;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 20px;
  max-height: 80vh;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  font-weight: normal;

  .jstree-anchor {
    white-space: normal;
    height: auto;
  }
  .jstree-default .jstree-anchor {
    height: auto;
  }

  & * {
    white-space: normal !important;
  }

  & > ul {
    background-color: transparent !important;
  }
`;

import { useMemo } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrid2Plus } from "@fortawesome/pro-light-svg-icons";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

import { InputGroup, Label, StyledInput } from "../Form";
import Counter from "../Counter";
import useBulkPaste from "./useBulkPaste";
import { FieldError } from "../Form/styles";

const InputContainer = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid #dddbda;
  background-color: #ffffff;
  padding-right: 10px;
  cursor: pointer;

  ${(props) =>
    props.$isDisabled &&
    `
    background-color: #E4E4E4;
  `}
`;

const ReadOnlyInput = styled(StyledInput)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
  pointer-events: none;
  border: none;

  ::placeholder {
    color: #9f9f9f;
  }
  ::-webkit-input-placeholder {
    color: #9f9f9f;
  }
  :-ms-input-placeholder {
    color: #9f9f9f;
  }

  ${(props) =>
    props.$size === "small"
      ? `
    height: 25px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    padding-left: 10px;
  `
      : ""}
`;

const RightContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
`;

const GridIcon = styled(FontAwesomeIcon)`
  height: 18px;
  color: ${(props) => (props.$hasValues ? "#535353" : "#706e6b")};
`;

const StyledClearIndicator = styled.div`
  display: inline-block;
  color: #706e6b;
  cursor: pointer !important;

  svg {
    padding: 3px 6px;
    border-radius: 100%;

    &:hover {
      background-color: #f5f2fc;
    }
  }
`;

const BulkPasteReadOnlyInput = () => {
  const { id, size, label, values, showModal, clearValues, isDisabled } =
    useBulkPaste();

  const readOnlyInputValue = useMemo(() => values?.join(", ") || "", [values]);

  const isCounterVisible = useMemo(() => values?.length, [values]);

  const counterText = useMemo(() => values?.length.toLocaleString(), [values]);

  const Container = ({ children }) =>
    size === "small" ? (
      <>{children}</>
    ) : (
      <InputGroup>
        <Label htmlFor={id}>{label}</Label>
        {children}
      </InputGroup>
    );

  return (
    <Container>
      <InputContainer onClick={showModal} $isDisabled={isDisabled}>
        <ReadOnlyInput
          id={id}
          type="text"
          placeholder={`Add ${label}`}
          value={readOnlyInputValue}
          readOnly
          $size={size}
          disabled={isDisabled}
          data-cy="bulk-paste-input"
        />
        <RightContainer>
          {values?.length ? (
            <StyledClearIndicator onClick={clearValues}>
              <FontAwesomeIcon icon={faXmark} />
            </StyledClearIndicator>
          ) : null}
          <Counter
            text={counterText}
            visible={values?.length > 0 && isCounterVisible}
            size="small"
          />
          <GridIcon icon={faGrid2Plus} $hasValues={values?.length} />
        </RightContainer>
      </InputContainer>
      {/* Update later */}
      <FieldError />
    </Container>
  );
};

export default BulkPasteReadOnlyInput;
